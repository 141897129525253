import {history} from "../components/router/CustomBrowserRouter";
import animalsDB from "../database/animalsDB";
import {isNil} from "lodash";

/**
 * zamienia linki z postaci ":FarmID/tw/ui/stry" na "IdFermy/tw/ui/stry" jesli przkazemy w params klucz FarmID: "IdFermy"
 * przydatne
 * @param linkString
 * @param params
 * @param options {object}
 * @param options.skipNulls {boolean}
 * @return {string}
 */
export const interpolateLink = (linkString = "", params = {}, options = {}) => {
    const linkArr = linkString.split("/");
    for (let i = 0; i < linkArr.length; i++) {
        if (linkArr[i].startsWith(":")) {
            const value = params[linkArr[i].replace(":", "")];
            if (!isNil(value)) {
                linkArr[i] = value;
            } else if (!options?.skipNulls) {
                linkArr[i] = "undefined";
            }
        }
    }
    return linkArr.join("/");
};

export const handleAnimalInventoryRowClick = (data, {extraData = {}} = {}, additionalState = {}, goBack = false) => {
    const {location: {pathname, search}} = history;
    let searchParams = new URLSearchParams(search);
    searchParams.delete("documentsGroup");
    searchParams.delete("documentsAnimal");
    searchParams.delete("documentsRFID");
    searchParams.delete("documentsTechnoGroup");
    searchParams.delete("FarmID");
    if (data) {
        searchParams.set(data.isGroup ? (data.isTechnoGroup ? "documentsTechnoGroup" : "documentsGroup") : "documentsAnimal", data.id);
    }
    for (let [key, value] of Object.entries(extraData)) {
        searchParams.set(key, `${value}`);
    }
    if (Object.keys(extraData).length) {
        searchParams.delete("location");
    }
    let isRFIDApp = pathname.includes("/mobile");
    if (isRFIDApp) {
        history.push({
            pathname: pathname.split("/mobile")[0] + `/mobile/information/${data?.id}`,
            state: {
                animals: [animalsDB.getAnimalById(data?.id)],
                back: true
            }
        });
    } else {
        if (goBack) {
            history.goBack();
        } else {
            history.push({
                pathname,
                search: searchParams.toString(),
                state: {
                    ...additionalState
                }
            });
        }
    }
};

export const downloadFile = (url) => {
    let link = document.createElement("a");
    // link.setAttribute("download", FileName);
    link.setAttribute("href", url);
    document.body.appendChild(link);
    link.click();
    link.remove();
}

