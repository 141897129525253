import ForceUpdateTypes from "@wesstron/utils/Api/constants/forceUpdateTypes";
import IoTResponseStatus from "@wesstron/utils/Api/constants/iotResponseStatus";
import * as RoleTypes from "@wesstron/utils/Api/constants/roleTypes";
import * as UserTypes from "@wesstron/utils/Api/constants/userTypes";
import { Auth } from "aws-amplify";
import v4 from "aws-signature-v4";
import Queue from "better-queue";
import QueueMemory from "better-queue-memory";
import i18next from "i18next";
import { get, isArray, isEmpty, isFunction, isNil, isObject, isString } from "lodash";
import moment from "moment";
import mqtt from "mqtt";
import pako from "pako";
import queryString from "querystring";
import { notify } from "reapop";
import { show } from "redux-modal";
import { getAggregatedData } from "../actions/aggregatedActions";
import { listAnimalDynamoDB } from "../actions/animalsActions";
import { getBreedingCounters as getBreedingCountersAction } from "../actions/breedingCounterActions";
import {
    changeAddressingState,
    fetchDevices,
    foundETHDevice,
    getGatewayAlertsState,
    gotETHDeviceParams,
    offlineSyncDevice,
} from "../actions/devicesActions";
import { getUploadDeviceStatusDelta } from "../actions/devicesDebugActions";
import { listDictionariesDynamoDB } from "../actions/dictionaryActions";
import { getEconomy as getEconomyAction } from "../actions/economyActions";
import { getEvents } from "../actions/eventsActions";
import { fetchBuildingsByFarmID } from "../actions/farmsActions";
import {
    getFeedState,
    getFeedStateDelta,
    getFeedStateRFID,
    getFeedStateRFIDDelta,
    setFeedingForPig,
} from "../actions/feedingActions";
import { getCreatedProtocols, getFiles, getLastModifiedFiles } from "../actions/filesActions";
import { listGroupsDynamoDB } from "../actions/groupActions";
import { getTranslation } from "../actions/localizationActions";
import { onRPIGetCommands, onRPIResponse } from "../actions/managePCActions";
import { changeMapShadow, changeMapShadowDelta } from "../actions/mapShadowActions";
import { onPingSuccess, setLocalConnection } from "../actions/mqttActions";
import { getLatestNotifications } from "../actions/notificationsActions";
import { getPreferences } from "../actions/preferencesActions";
import { getProjects } from "../actions/projectActions";
import { listAthenaReports } from "../actions/raportsActions";
import { getSales } from "../actions/salesActions";
import { checkMaintenanceStatus } from "../actions/serviceActions";
import { getSettings } from "../actions/settingsAction";
import { listSettlementsDynamoDB } from "../actions/settlementActions";
import { changeAnimalShadow, changeShadowState, setShadowLoading } from "../actions/shadowsActions";
import { getTasksAPI, getTasksWithReplace } from "../actions/taskActions";
import { fetchServiceAccounts, fetchTranslators, getEmployees, getUser } from "../actions/userActions";
import { setGlobalLoadingText } from "../actions/viewActions";
import Paths from "../api/paths";
import { ModalName as DelayActionModal } from "../components/modals-new/delayed-action/DelayedActionModal";
import { history } from "../components/router/CustomBrowserRouter";
import config from "../conf/config";
import DevTypes from "@wesstron/utils/Api/constants/devTypes";
import { IntervalTimes } from "../constans/intervals";
import {
    AlarmControlPanelCommandTypes,
    BridgeCommandTypes,
    ClimateCommandTypes,
    ClimateDriverCommandTypes,
    DispenserDriverCommandTypes,
    DispenserNRFCommandTypes,
    GatawayCommandTypes,
    MessageCommands,
    MessageTypes,
    SeparationCageCommandTypes,
    TopicTypes,
    UniversalBoardCommandTypes,
} from "../constans/mqttMessages";
import devicesDB from "../database/devicesDB";
import { authUser, invokeApig } from "../libs/awsLib";
import { myID } from "../libs/generateID";
import { isDataLoading } from "../selectors/dataLoaderSelector";
import store from "../store/store";
import { manageAsyncNotification } from "../utils/AsyncResponseUtils";
import { getBreedingCounters, getEconomy, getSales as getSalesAPI } from "../utils/DataLoaderUtils";
import {
    deviceIdToName,
    enableMQTTAggregatedData,
    onGetFullDevStateFailure,
    onGetFullDevStateSuccess
} from "../utils/DevicesUtils";
import { getIOTAttributes, getMessageForStatus } from "../utils/IOTUtils";
import { isMobile } from "../utils/MobileUtils";
import { checkIfUserHasAccessToProject, checkIfUserHasRole, checkIfUserIsService } from "../utils/NewRolesUtils";
import { logout } from "../utils/UserUtils";
import { catchify, cloneFast, waitUntil } from "../utils/Utils";
import { bugsnagClient } from "../utils/bugsnagUtils/BugsnagUtils";
import { getParser } from "../utils/monitor/StructureUtils";
import AlarmsIOT from "./shadows/AlarmsIOT";
import { template } from "../utils/TextUtils";
import IsSpecialMessage from "./utils/IsSpecialMessage";
import IsMappedToState from "./utils/IsMappedToState";
import { decompressData } from "../utils/GZipUtils";

require("setimmediate");

const IntervalTime = IntervalTimes.DEVICE_MQTT_RESEND_STATE;
export const LOCAL_MQTT_ADDRESS = "fetura.wesstron.";

const IOT_IDX_OFFSET = 1;
export let STAGE = config.iot.PREFIX;

class NewIOT {
    constructor() {
        if (process.env.NODE_ENV === "test") {
            let obj = {};
            Error.captureStackTrace(obj);
            if (!obj.stack.includes("NewIOT.spec")) {
                throw new Error("NewIOT is not supposed to be instantiated in test mode");
            }
        }
        this.url = "";
        this.window = null;
        this.msgChunks = new Map();
        this.client = undefined; //klient mqtt
        this.offlineChecker = null; // timeout, który sprawdza czy powinien polaczyc sie do offline
        this.isAuthenticatingToLocal = false; // zmienna mowiaca o logowaniu do offline
        this.subscriptions = []; //lista subskrypcji
        this._checkSubBeforeSending = true;
        this.offlineReconnectRetries = 3;
        this.intervalMap = new Map();
        this.localMQTTAddress = null;
        this.counters = {
            dCounter: 0,
            fCounter: 0,
            _time: +new Date(),
            forceUpdate: {},
        };
        this.localGWData = null;
        /*


            CYKL
            WCHODZISZ NA WIDOK WYSYLASZ START SENDING DEVICE STATE ZAPAMIETUJESZ ID WIADOMOSCI
            NA WYJSCIU ROBISZ STOP SENDING DEVICE STATE Z ID WIADOMOSCI ESSA PROSTE

        */

        this.afterProcessDelay = 25; //czas w ms miedzy wysyłanymi komendami z kolejki
        this.preconditionRetryTimeout = 2; //czas co jaki ma sprawdzac czy odzyskal polaczenie z mqtt w sekundach
        this.timeout = undefined;
        this.timeToClearQueue = 1; //czas w minutach po ktorych nastapi wyczyszczenie kolejki w przyapdku ciaglego braku polaczenia
        this.waitedSetMessage = [];
        this.sendingRefreshToken = false;
        this.reconnectsAmount = 0;
        //tworzenie kolejki mqtt
        this.queue = new Queue(
            (input, cb) => {
                this.runQueueTask(input, cb);
            },
            {
                store: new QueueMemory(),
                afterProcessDelay: this.afterProcessDelay,
                maxTimeout: 10000,
                batchDelayTimeout: 1000,
                // concurrent: 5,
                precondition: (cb) => {
                    console.log("client %s connected %s", this.client ? "Y" : "n", this.client?.connected ? "Y" : "n");
                    if (this.client && this.client.connected) {
                        if (this.timeout) {
                            console.log("clear timeout");
                            clearTimeout(this.timeout);
                            this.timeout = undefined;
                        }
                        if (
                            this._checkSubBeforeSending &&
                            get(this.client, "options.hostname", "") !==
                            this.localMQTTAddress &&
                            this.checkSubscriptions()
                        ) {
                            cb(null, false);
                        } else {
                            console.log(
                                "sendingRefreshToken %s isAuthenticatingToLocal %s",
                                this.sendingRefreshToken ? "Y" : "n",
                                this.isAuthenticatingToLocal ? "Y" : "n"
                            );
                            if (
                                this.sendingRefreshToken ||
                                this.isAuthenticatingToLocal
                            ) {
                                console.log("sending refresh token");
                                cb(null, false);
                            } else {
                                cb(null, true);
                            }
                        }
                    } else {
                        if (!this.timeout) {
                            console.log("clear timeout");
                            this.timeout = setTimeout(() => {
                                this.clearQueue();
                                this.timeout = undefined;
                            }, 1000 * 60 * this.timeToClearQueue);
                        }
                        cb(null, false);
                    }
                },
                preconditionRetryTimeout: this.preconditionRetryTimeout * 1000,
                id: (task, cb) => {
                    let command = task.message;
                    cb(null, command.MsgId);
                },
                //wykomentowalem bo nie idzie ustawic rzeczy za 1. razem, trzeba wyslac druga komende aby pierwsza przesla
                // merge: (oldTask, newTask, cb) => {
                //     cb(null, oldTask);
                // },
                concurrent: 3,
            }
        );

        this.queue.on("task_finish", (taskId, result) => {
            if (typeof result === "object") {
                this.checkResult(result);
            }
        });

        this.queue.on("error", (err) => {
            console.error(new Error(err));
        });

        this.queue.on("task_failed", (taskId, errorMessage) => {
            //tylko set moze nie przejsc gdyz gety maja callback od razu
            // console.log("FAILED", this.waitedSetMessage, errorMessage);
            console.log(taskId, errorMessage);
            try {
                let waitedMessage = this.waitedSetMessage.find(
                    (item) => item.message.MsgId === taskId
                );
                if (waitedMessage.notification) {
                    let notif = {
                        id: waitedMessage.notification.id,
                        ...waitedMessage.notification.loading,
                    };
                    // TODO sprawdzic czy wejdzie w ten warunek jak wysypie sie API
                    if (
                        (errorMessage === "Failed to fetch" ||
                            errorMessage.statusCode) &&
                        waitedMessage.notification.apiFailed
                    ) {
                        notif = {
                            ...notif,
                            ...waitedMessage.notification.apiFailed,
                        };
                    } else {
                        notif = {
                            ...notif,
                            ...waitedMessage.notification.error,
                        };
                    }
                    store.dispatch(notify(notif));
                }
                if (waitedMessage.onError)
                    waitedMessage.onError(new Error(errorMessage), waitedMessage.message);
                this.waitedSetMessage = this.waitedSetMessage.filter(
                    (item) => item.message.MsgId !== taskId
                );
            } catch (e) {
                console.error(e);
                this.waitedSetMessage = [];
            }
        });

        this.onStateMessageArrived = this.onStateMessageArrived.bind(this);
        this.onAggregatedDataMessageArrived =
            this.onAggregatedDataMessageArrived.bind(this);
        this.onMessageArrived = this.onMessageArrived.bind(this);
        this.onAlarmsMessageArrived = this.onAlarmsMessageArrived.bind(this);
        this.onResponsesMessageArrived =
            this.onResponsesMessageArrived.bind(this);
    }

    clearQueue() {
        console.warn("clearQueue");
        this.queue.use(new QueueMemory());
    }

    /**
     *
     * @return {boolean} zwracamy wartosc jesli nie ma wysylac zapytan po MQTT
     */
    checkSubscriptions() {
        const state = store.getState();
        const { farmDevices: { devices } } = state;
        const hasGateways = devices.some(d => [DevTypes.GATEWAY, DevTypes.ALARM_CONTROL_PANEL].includes(d.DevType));
        // jesli mamy gejtweje to powinno byc zasubowane conajmniej 4 kanaly zeby wyslac po mqtt
        return hasGateways
            ? this.subscriptions.length < 4
            : this.subscriptions.length < 2;
    }

    /**
     * czy ma sprawdzac ilosc zasubwanych kanalow przed wyslaniem komendy mqtt
     * @param _checkSubBeforeSendingCommand
     */
    setSubscriptionsCheck(_checkSubBeforeSendingCommand) {
        this._checkSubBeforeSending = !!_checkSubBeforeSendingCommand;
    }

    runQueueTask(input, cb) {
        //funkcja ktora wysyla na mqtt, wykonuje sie przy dodaniu do kolejki
        // console.log("INPUT", input);
        console.warn("QUEUE_TASK_EXECUTED", input);
        const host = get(this.client, "options.host", "");
        console.log(host);
        if (
            (host.includes(this.localMQTTAddress) ||
                host.includes(this.localMQTTAddress)) &&
            Array.isArray(input.message.Command) &&
            !input.message.Command.includes(MessageCommands.AUTH_USER) &&
            !input.message.Command.includes(MessageCommands.REFRESH_TOKEN)) {
            let { user: { user: { Login } } } = store.getState();
            let tokenExpireTime = +localStorage.getItem(`${Login}.createTokenTime`);
            if (tokenExpireTime > new Date().getTime()) {
                input.message.CData.token = localStorage.getItem(
                    `${Login}.token`
                );
                console.log(
                    "%c Sending by offline",
                    "color: white;background: #4eb848"
                );
                this.sendMqttMessage({
                    topic: input.topic,
                    message: input.message,
                    farm: input.farm,
                });
            } else {
                let refreshTokenExpire = +localStorage.getItem(
                    `${Login}.createRefreshTokenTime`
                );
                if (refreshTokenExpire > new Date().getTime()) {
                    let refreshToken = localStorage.getItem(
                        `${Login}.localRefreshToken`
                    );
                    if (!this.sendingRefreshToken) {
                        this.refreshToken(refreshToken, input);
                    } else {
                        this.addMessageToQueue(input);
                    }
                } else {
                    logout();
                }
            }
        } else {
            this.sendMqttMessage({
                topic: input.topic,
                message: input.message,
                farm: input.farm,
                useAPI: input.useAPI,
            });
        }
        if (input.onSend) input.onSend(input.message);
        if (this.checkCommands(input.message.Command)) {
            // console.log("ADDING WAITEDMESSAGE", input);
            this.waitedSetMessage.push({
                ...input,
                callback: cb,
            });
            if (input.notification) {
                input.notification.id = input.message.MsgId;
                store.dispatch(
                    notify({
                        ...input.notification.loading,
                        id: input.message.MsgId,
                    })
                );
            }
        } else {
            // console.log("GET \"ADDING WAITEDMESSAGE\"");
            cb(null, true);
        }
    }

    onRefreshTokenSuccess = msg => {
        let { user: { user: { Login } } } = store.getState();
        localStorage.setItem(`${Login}.token`, msg.CAnsw.token);
        localStorage.setItem(
            `${Login}.createTokenTime`,
            msg.CAnsw.createTokenTime
        );
        localStorage.setItem(
            `${Login}.localRefreshToken`,
            msg.CAnsw.refreshToken
        );
        localStorage.setItem(
            `${Login}.createRefreshTokenTime`,
            msg.CAnsw.createRefreshTokenTime
        );
        this.sendingRefreshToken = false;
    };

    onRefreshTokenError = (error, msg) => {
        console.log(error, msg);
        console.error("cant refresh token");
        this.sendingRefreshToken = false;
        logout();
    };

    onRefreshTokenSend = (msg, trigger) => {
        this.sendingRefreshToken = true;
        this.addMessageToQueue(trigger);
    };

    refreshToken(refreshToken, trigger) {
        let msg = {
            MsgId: myID(),
            PktType: MessageTypes.REQUEST,
            RTime: new Date().getTime(),
            Command: [MessageCommands.REFRESH_TOKEN],
            CData: { refreshToken }
        };
        let topic = `${STAGE}devices/auth/${this.localGWData.clientID}/${this.localGWData.tName}`;
        this.createAndAddMessageToQueue(
            topic,
            msg,
            null,
            this.onRefreshTokenSuccess,
            this.onRefreshTokenError,
            (msg) => this.onRefreshTokenSend(msg, trigger)
        );
    }

    checkIfAlreadySubscribed(topic) {
        return this.subscriptions.includes(topic);
    }

    addToSubscriptions(topic) {
        // console.log("adding to sub list", sub, topic);
        this.subscriptions.push(topic);
        store.dispatch({
            type: "SUBSCRIBE_MQTT_TOPICS",
            payload: this.subscriptions.slice(),
        });
    }

    removeFromSubscriptions(topic) {
        this.subscriptions = this.subscriptions.filter(
            (item) => item !== topic
        );
        store.dispatch({
            type: "SUBSCRIBE_MQTT_TOPICS",
            payload: this.subscriptions.slice(),
        });
    }

    clearSubscriptions() {
        const { user: { user } } = store.getState();
        if (user) {
            let arr = this.subscriptions.filter(
                (t) =>
                    ![
                        `${STAGE}devices/state/${user.ClientID}/lambda`,
                        `${STAGE}devices/state/${user.LocalUserID}/lambda`,
                    ].includes(t)
            );
            for (let topic of arr) {
                if (this.client) this.client.unsubscribe(topic);
            }
            this.subscriptions = [
                `${STAGE}devices/state/${user.ClientID}/lambda`,
                `${STAGE}devices/state/${user.LocalUserID}/lambda`,
            ];
            store.dispatch({
                type: "SUBSCRIBE_MQTT_TOPICS",
                payload: this.subscriptions.slice(),
            });
        }
    }

    createMessage(
        topic,
        message,
        notification = undefined,
        onSuccess = undefined,
        onError = undefined,
        onSend = undefined,
        FarmID = null,
        useAPI = true
    ) {
        return {
            topic: topic,
            message: message,
            notification: notification,
            onSuccess,
            onError,
            onSend,
            farm: FarmID,
            useAPI,
        };
    }

    addMessageToQueue(message) {
        console.log(message);
        this.queue.push(message);
        this.queue.resume();
        //notyfikacja wyświetlana w przypadku braku neta. na razie takie tlumaczenie
        //bo jest zmieniane na i18 next, wiec nowego nie dodaje
        //todo - pliki i18next powinny byc trzymane offlline'owo bo w tym case'ie nam ich nie pobierze

        // wykomentowuje bo jest nie potrzebne, wywala bardzo czesto zanim jeszcze sie mqtt polaczy
        // if (this.client && !this.client.connected) {
        //     let n = {
        //         title: store.getState().language.lang.page.IOT.noConnection,
        //         message: store.getState().language.lang.page.IOT.noResponseFromDevice,
        //         dismissible: true,
        //         dismissAfter: 3000,
        //         status: "error"
        //     };
        //     store.dispatch(notify(n));
        // }
    }

    createAndAddMessageToQueue(
        topic,
        message,
        notification = undefined,
        onSuccess = undefined,
        onError = undefined,
        onSend = undefined,
        FarmID = null,
        useAPI = true
    ) {
        // console.log("CREATE MESSAGE", onSuccess, topic);
        if (this.queue.length < 0) {
            console.warn("queue fix");
            this.queue.length = 0;
        }
        this.addMessageToQueue(
            this.createMessage(
                topic,
                message,
                notification,
                onSuccess,
                onError,
                onSend,
                FarmID,
                useAPI
            )
        );
    }

    compressData(data) {
        // console.log("COMPRESSING", data);
        return pako.deflate(JSON.stringify(data), {
            level: 9,
            to: "string",
        });
    }

    //tymczasowe roziwazanie na czas wymiany w agg data cansw
    decompressData(data) {
        try {
            return decompressData(data);
        } catch (e) {
            return data;
        }
    }

    async createURL(endpoint) {
        if (await authUser()) {
            let creden = await Auth.currentCredentials();
            return v4.createPresignedURL(
                "GET",
                endpoint,
                "/mqtt",
                "iotdevicegateway",
                "",
                {
                    key: creden.accessKeyId,
                    secret: creden.secretAccessKey,
                    region: config.cognito.REGION,
                    protocol: "wss",
                    sessionToken: creden.sessionToken,
                }
            );
        }
    }

    subscribeAllTopics(ClientID = "") {
        for (let topic of this.subscriptions) {
            if (
                this.client &&
                this.client.connected &&
                !this.client.disconnecting
            ) {
                this.client.unsubscribe(topic);
            }
        }
        this.subscriptions = [];
        store.dispatch({
            type: "SUBSCRIBE_MQTT_TOPICS",
            payload: this.subscriptions.slice(),
        });
        let state = store.getState();
        const { user: { user }, farmDevices: { devices } } = state;
        let topics = [];
        if (ClientID || user.ClientID) {
            topics.push(
                `${STAGE}devices/state/${ClientID || user.ClientID}/lambda`
            );
        }
        if (user.LocalUserID) {
            topics.push(`${STAGE}devices/state/${user.LocalUserID}/lambda`);
        }
        const gateways = [];
        const things = [];
        for (let device of devices) {
            switch (device.DevType) {
                case DevTypes.GATEWAY:
                    gateways.push(device);
                    break;
                case DevTypes.ALARM_CONTROL_PANEL:
                    things.push(device);
                    break;
                default:
                    break;
            }
        }
        for (let gw of gateways) {
            if (this.client?.options?.hostname === this.localMQTTAddress) {
                topics.push(`${STAGE}devices/auth/${ClientID || user.ClientID}/${gw.DevID}`);
            }
            topics.push(
                `${STAGE}devices/responses/${ClientID || user.ClientID}/${gw.DevID
                }`
            );
            topics.push(
                `${STAGE}devices/state/${ClientID || user.ClientID}/${gw.DevID}`
            );
            topics.push(
                `${STAGE}devices/aggregatedData/${ClientID || user.ClientID}/${gw.DevID
                }`
            );
            if (checkIfUserIsService()) {
                topics.push(
                    `${STAGE}devices/rpiResponses/${ClientID || user.ClientID
                    }/${gw.DevID}`
                );
            }
        }

        for (let thing of things) {
            // todo: dodać STAGE i ClientID jak urządzenie będzie ogarniało :D
            console.log("should sub to %s here", thing.DevID);
            // topics.push(`devices/responses/UNBINDED/${thing.DevID}`);
            // topics.push(`devices/data/UNBINDED/${thing.DevID}`);
            // topics.push(`${STAGE}devices/data/${ClientID || user.ClientID}/${thing.DevID}`);
            topics.push(`${STAGE}devices/responses/${ClientID || user.ClientID}/${thing.DevID}`);
            topics.push(`${STAGE}devices/state/${ClientID || user.ClientID}/${thing.DevID}`);
        }

        for (let topic of topics) {
            this.subscribeTopic(topic);
        }
        store.dispatch({
            type: "SUBSCRIBE_MQTT_TOPICS",
            payload: topics,
        });
    }

    subscribeGateways(gateways) {
        let topics = [];
        for (let gateway of gateways) {
            let ClientID = gateway.CliIDFaID_C.split("+")[0];
            // subuje tylko state, bo duzy spam idzie
            topics.push(
                `${STAGE}devices/alarmState/${ClientID}/${gateway.DevID}`
            );
        }
        for (let topic of topics) {
            this.subscribeTopic(topic);
        }
        store.dispatch({
            type: "SUBSCRIBE_MQTT_TOPICS",
            payload: topics,
        });
    }

    resetCounters() {
        this.counters = {
            dCounter: 0,
            fCounter: 0,
            _time: +new Date(),
            forceUpdate: {},
        };
    }

    getCounters(print = false) {
        const tDiff = +new Date() - this.counters._time;
        const result = {
            deltaStateSpeed: this.counters.dCounter / tDiff,
            fullStateSpeed: this.counters.fCounter / tDiff,
            deltaCounter: this.counters.dCounter,
            fullCounter: this.counters.fCounter,
            speed: (this.counters.dCounter + this.counters.fCounter) / tDiff,
            counter: this.counters.dCounter + this.counters.fCounter,
            forceUpdate: JSON.parse(JSON.stringify(this.counters.forceUpdate)),
        };
        if (print) {
            console.log("[getCounters]", result);
        }
        return result;
    }

    async createLocalClient(ipAddress, onConnected = null) {
        this.localMQTTAddress = ipAddress;
        this.createClient(
            `ws://${ipAddress}`,
            {
                keepalive: 60,
                port: 9001,
                hostname: ipAddress,
                protocol: "ws",
            },
            onConnected
        );
    }

    checkInternetConnectivity(sub) {
        setTimeout(async () => {
            try {
                let response = await fetch("https://icanhazip.com/");
                if (response?.status === 200) {
                    console.log("xd1");
                    this.endConnection();
                    await this.connectToAWS();
                    if (sub) {
                        setTimeout(() => {
                            store.dispatch(getUser(sub, false));
                        }, 1000);
                    }
                } else {
                    this.checkInternetConnectivity(sub);
                }
            } catch (e) {
                this.checkInternetConnectivity(sub);
            }
        }, 5000);
    }

    createClient(url, options = {}, onConnected = null) {
        const state = store.getState();
        const clientId = `${state.user.user?.LocalUserID || "mqttjs"}_${myID()}`;
        console.log("MQTT: Creating client", clientId);
        this.client = mqtt.connect(url, {
            clientId,
            keepalive: 10,
            resubscribe: false,
            ...options,
        });

        this.client.on("connect", () => {
            this.reconnectsAmount = 0;
            console.log("MQTT: Client connected", this.client);
            this.subscribeAllTopics();
            (!store.getState().mqtt.connected) && store.dispatch({ type: "MQTT_CONNECT_FULFILLED" });
            if (get(this.client, "options.host", "").includes("a1h52tmlgb9hda-ats.iot.eu-central-1.amazonaws.com")) {
                console.log("Cancelling offline checker");
                clearTimeout(this.offlineChecker);
                this.offlineChecker = null;
            }
            onConnected && onConnected();
        });

        this.client.on("reconnect", async () => {
            this.subscriptions = [];
            this.reconnectsAmount++;
            console.error("reconnect");
            if (this.reconnectsAmount < this.offlineReconnectRetries) {
                store.dispatch({ type: "MQTT_CONNECT_PENDING" });
            } else {
                store.dispatch({ type: "MQTT_KEEPS_RECONNECTING" });
                if (document.visibilityState !== 'hidden') {
                    bugsnagClient.notify(new Error("MQTT: Client reconnecting"));
                }
                // console.log(this.client);
                // const hostName = get(this.client, "options.host", "");
                // if (!hostName.includes("a1h52tmlgb9hda-ats.iot.eu-central-1.amazonaws.com")) {
                //     console.log("xd2");
                //     this.endConnection();
                //     await this.connectToAWS();
                // }
            }
            if (
                get(this.client, "options.host", "").includes(
                    "a1h52tmlgb9hda-ats.iot.eu-central-1.amazonaws.com"
                )
            ) {
                this.url = await this.createURL(
                    "a1h52tmlgb9hda-ats.iot.eu-central-1.amazonaws.com"
                );
                console.log("MQTT: Client reconnect");
                // if (!this.offlineChecker) {
                //     console.log("Creating offline checker");
                //     this.offlineChecker = setTimeout(() => {
                //         this.reconnectsAmount = 0;
                //         const {user: {attributes, user}, location: {farm}} = store.getState();
                //         if (user?.LocalMqttSecret) {
                //             console.log("Authorizing on offline");
                //             this.endConnection();
                //             console.log("Authorize attempt");
                //             this.localAuthUser(attributes.login, user.LocalMqttSecret, null, msg => {
                //                 console.log(msg);
                //                 store.dispatch({
                //                     type: "GET_USER_FULFILLED",
                //                     payload: msg.CAnsw.user
                //                 });
                //                 localStorage.setItem(`${msg.CAnsw.user.Login}.token`, msg.CAnsw.token);
                //                 localStorage.setItem(`${msg.CAnsw.user.Login}.createTokenTime`, msg.CAnsw.createTokenTime);
                //                 localStorage.setItem(`${msg.CAnsw.user.Login}.localRefreshToken`, msg.CAnsw.refreshToken);
                //                 localStorage.setItem(`${msg.CAnsw.user.Login}.createRefreshTokenTime`, msg.CAnsw.createRefreshTokenTime);
                //                 if (!msg.CAnsw.user.FarmData.find(item => item.FarmID === farm)) {
                //                     // user jest na zlej fermie wiec przekieruja na farm chooser
                //                     history.push("/");
                //                 }
                //                 this.checkInternetConnectivity(msg.CAnsw.user.sub);
                //                 console.log("Setted");
                //             }, async (err, msg) => {
                //                 console.log(err, msg);
                //                 // jezeli nadal bedzie losowo wylogowywac to trzeba szukac bledu w innym miejscu
                //                 this.endConnection();
                //                 this.checkInternetConnectivity();
                //                 // logout();
                //             });
                //         }
                //     }, 10000)
                // }
            }
        });

        this.client.on("message", (topic, message) => {
            this.onMessageArrived(topic, message);
        });

        this.client.stream.on("error", async (e) => {
            console.log("error stream", e);
            try {
                if (
                    !e.srcElement.url.includes(
                        "a1h52tmlgb9hda-ats.iot.eu-central-1.amazonaws.com"
                    )
                ) {
                    this.clearQueue();
                    console.log("xd3");
                    this.endConnection(e.srcElement.url);
                    if (window.location.href.includes("login")) {
                        store.dispatch({
                            type: "USER_LOGIN_REJECTED",
                            error: null,
                        });
                    }
                }
            } catch (e) {
                console.error(e);
            }
        });

        this.client.on("error", (err) => {
            console.error("MQTT: Client error: ", err);
            this.clearSubscriptions();
            bugsnagClient.notify(new Error("MQTT: Client error"), (event) => {
                event.addMetadata("feedback", {
                    causedBy:
                        err && err.toString
                            ? err.toString()
                            : "No info available",
                    stack: err && err.stack ? err.stack : "No info available",
                });
            });
        });

        this.client.on("close", console.error);
        this.client.on("offline", console.error);
        this.client.on("disconnect", console.error);
    }

    async connectToAWS() {
        if (!this.client || !this.client.connected) {
            this.url = await this.createURL(
                "a1h52tmlgb9hda-ats.iot.eu-central-1.amazonaws.com"
            );
            this.createClient(this.url, {
                protocol: "wss",
                transformWsUrl: () => {
                    return this.url;
                },
            });
        } else {
            this.subscribeAllTopics();
        }
    }

    async startConnection() {
        try {
            store.dispatch({ type: "MQTT_CONNECT_PENDING" });
            await this.connectToAWS();
        } catch (e) {
            console.error("Blad przy polaczeniu ", e);
        }
    }

    onLogout() {
        // czyscimy kolejke
        this.clearQueue();
        // czyscimy intervaly
        this.removeAllIntervals();
        // wywalamy klienta
        this.endConnection();
        // czyscimy jeszcze raz kolejke
        this.clearQueue();
    }

    endConnection(url) {
        if (url) {
            if (this.client.options.href === url) {
                this.clearSubscriptions();
                if (this.client) {
                    this.client.end(false, () => store.dispatch({ type: "MQTT_CONNECT_FAILURE" }));
                }
                this.client = undefined;
            }
        } else {
            this.clearSubscriptions();
            if (this.client) {
                this.client.end(false, () => store.dispatch({ type: "MQTT_CONNECT_FAILURE" }));
            }
            this.client = undefined;
        }
    }

    sendToChannel(message) {
        try {
            console.warn("PUBLISH", message);
            this.client.publish(
                message.topic,
                JSON.stringify(message.message),
                {},
                (err) => {
                    if (err) {
                        bugsnagClient.notify(
                            new Error(
                                "MQTT: Send MQTT message failure (Client.publish())"
                            ),
                            (event) => {
                                event.addMetadata("feedback", {
                                    causedBy: err.toString
                                        ? err.toString()
                                        : "No info available",
                                    stack: err.stack
                                        ? err.stack
                                        : "No info available",
                                });
                            }
                        );
                        console.error(
                            "MQTT: Send MQTT message failure (Client.publish())",
                            err
                        );
                    }
                }
            );
        } catch (e) {
            bugsnagClient.notify(
                new Error("MQTT: Send MQTT message failure"),
                (event) => {
                    event.addMetadata("feedback", {
                        causedBy:
                            e && e.toString
                                ? e.toString()
                                : "No info available",
                        stack: e && e.stack ? e.stack : "No info available",
                    });
                }
            );
            console.error("MQTT: Send MQTT message failure: ", e);
        }
    }

    /**
     * Funkcja dzielaca CData na mniejsze paczki
     *
     * @param data obiekt CDatat z wiadomosci do wyslania
     * @param chunks w głównbym wywolaniu nie podajemy tego parametru jest on potrzebyn do wywołań rekurencyjnych
     * @return {*[]}
     */
    isMoreThan120kB(data, chunks = []) {
        let sizePkg = 120 * 1024;
        console.log(data.length);

        if (data.length > sizePkg) {
            chunks.push(data.slice(0, sizePkg));
            data = data.slice(sizePkg, data.length);
            // console.error("isMoreThan120kB if\r\nchunks:",chunks );
            // console.error("isMoreThan120kB if\r\ndata:",data );
            return this.isMoreThan120kB(data, chunks);
        } else {
            // console.log(chunks);
            //jesli caly obiekt nie jest wiekszy niz sizePkg to zwroc obiekt w przeciwnym razie string
            // if (chunks.length === 0) {
            //     return JSON.parse(data);
            // }
            //todo - usunięto JSON.parse po przetestowaniu z obu stron
            chunks.push(JSON.parse(data));
            //chunks.push(data);
            // console.error("isMoreThan120kB else\r\nchunks:",chunks );
            // console.error("isMoreThan120kB else\r\ndata:",data );
            return chunks;
        }
    }

    /**
     * funkcja przygotowujaca wiadomosci do wyslania
     * dodaje ChunkID, ChunkOffset i ChunkSize do wiadomosci wiekszy niz sizePkg
     * @param data wiadomosc do wyslania obiekt mqtt
     * @param chunks podzielona CData na mniejsze paczki
     * @return {Uint8Array|BigInt64Array|any[]|Float64Array|Int8Array|Float32Array|Int32Array|Uint32Array|Uint8ClampedArray|BigUint64Array|Int16Array|Uint16Array}
     */
    prepareToPublish(data, chunks) {
        if (chunks.length > 1) {
            //console.log("prepareToPublish", chunks.length)
            //przygotowywanie jesli jest obiekt jest wiekszy i musial byc podzielony
            let chunksID = myID();
            chunks = chunks.map((chunk, i) => {
                let _data = Object.assign({}, data);
                _data.ChunksID = chunksID;
                _data.ChunksOffset = i;
                _data.ChunksSize = chunks.length;
                _data.CData = chunk;
                //console.log("prepareToPublish data", _data)
                return _data;
            });
            //console.log("prepareToPublish chunks", chunks)
            return chunks;
        } else {
            //przypadek jak wiadomosc jest w calosci
            data.CData = chunks[0];
            return [data];
        }
    }

    async sendMqttMessage(message) {
        //dzielenie wiadomosci na mniejsze jesli przekracza rozmiar (max128kb)
        // console.log("sendMqttMessage", message)
        let CData = JSON.stringify(message.message.CData);
        let chunks = this.isMoreThan120kB(CData, []);
        // console.log(chunks);
        //utworzenie kilku wiadomosci z jednej jesli przekroczyla rozmiar
        let messages = this.prepareToPublish(message.message, chunks);
        //console.log("sendMqttMessage 2", messages)
        messages.forEach((value) => {
            //podstawienie do wiadomosci zaktualizowanej o nowe pola jesli takie wystapily
            message.message = value;
            if (
                message.useAPI === true &&
                get(this.client, "options.host", "").includes("amazonaws.com")
            ) {
                if (message.topic.includes("commands")) {
                    const state = store.getState();
                    let { location: { farm } } = state;
                    if (message.farm) farm = message.farm;
                    if (farm) {
                        invokeApig({
                            ...Paths.commandProxy({ farmID: farm }),
                            body: {
                                ...message.message,
                                Topic: message.topic,
                            },
                        }).catch((e) => {
                            let waitedMessage = this.waitedSetMessage.find(
                                (item) =>
                                    item.message.MsgId === message.message.MsgId
                            );
                            if (waitedMessage) {
                                waitedMessage.callback(e, null);
                            }
                        });
                    }
                } else {
                    this.sendToChannel(message);
                }
            } else {
                this.sendToChannel(message);
            }
        });
    }

    /**
     * Metoda subskrybujaca sie do danego topica i dodajaca do listy subskrypcji jezeli jeszcze nie zostal zasubskrybowany
     * @param topic             topic mqtt
     */
    subscribeTopic(topic) {
        if (
            this.client &&
            this.client.connected &&
            !this.client.disconnecting
        ) {
            try {
                if (!this.checkIfAlreadySubscribed(topic)) {
                    console.warn("SUBSCRIBING TO: " + topic);
                    this.client.subscribe(topic);
                    this.addToSubscriptions(topic);
                }
            } catch (err) {
                console.error("sub failure:" + err);
            }
        }
    }

    /**
     * Metoda subskrybuje do kanalu o alarmach
     * @param ClientID      ID klienta
     */
    // subscribeAlarmTopic(ClientID) {
    //     // let topic = `${STAGE}devices/alarms/${ClientID}`;
    //     let topic = `${STAGE}devices/alarms`;
    //     this.subscribeTopic(topic);
    // }

    /**
     * Metoda usuwajaca subskrypcje z listy i klienta
     * @param topic     topic ktory ma zostac odsubskrybowany
     */
    unsubscribeTopic(topic) {
        try {
            if (this.client && this.client.connected && !this.client.disconnecting) this.client.unsubscribe(topic);
            this.removeFromSubscriptions(topic);
        } catch (err) {
            console.error("unsub failure: " + err);
        }
    }

    /**
     * Metoda odsubskrybowujaca response topci dla gatewaya
     * @param ClientID      ID klienta
     * @param GatewayID     ID gateway (raspberry)
     */
    // unsubscribeResponseTopic(ClientID, GatewayID) {
    //     let topic = `${STAGE}devices/responses/${ClientID}/${GatewayID}`;
    //     this.unsubscribeTopic(topic);
    // }

    // unsubscribeAlarmTopic(ClientID) {
    //     // let topic = `${STAGE}devices/alarms/${ClientID}`;
    //     let topic = `${STAGE}devices/alarms`;
    //     this.unsubscribeTopic(topic);
    // }

    /**
     * Metoda subskrybuje do kanału i pyta dane urządzenie o swój stan
     * @param ClientID      ID klienta
     * @param LocalUserID   ID uzytkownika
     * @param GatewayID     ID gatewaya (raspberry)
     * @param DeviceID      ID urzadzenia o ktore ma zostac wyslane zapytanie
     * @param onSuccess
     * @param onFailure
     */
    pingDevice(
        ClientID,
        LocalUserID,
        GatewayID,
        DeviceID,
        onSuccess,
        onFailure
    ) {
        let topic = `${STAGE}devices/state/${ClientID}/${GatewayID}/${LocalUserID}`;
        let command = {
            MsgId: myID(),
            PktType: MessageTypes.REQUEST,
            DeviceId: DeviceID,
            RTime: new Date().getTime(),
            Command: [MessageCommands.PING],
            CData: { ping: "pong" }
        };
        // let sendTopic = `${STAGE}devices/commands/${ClientID}/${GatewayID}/${LocalUserID}`;
        // let sendTopic = `${STAGE}devices/state/UNBINDED/GW_SB_18_09_04_110247371`;
        // let sendTopic = `${STAGE}devices/state/UNBINDED/GW_SB_18_09_04_110247371`;
        this.createAndAddMessageToQueue(
            topic,
            command,
            null,
            onSuccess,
            onFailure
        );
    }

    /**
     * Metoda subskrubuje do kanalu, na ktorym sa wysylane stany urzadzen oraz wysyla zapytanie do gatewaya
     * o aktualne dane. Nastepnie ustawia interval na 10 aby wysylac prosbe o kolejne dane.
     * @param dev     Lista/pojedyncze urzedzenie (lub jego ID) do pobrania np. "DevID" / ["DevID","DevID", device] / device
     * @param onSuccess     Funkcja, ktora wykona się po odpowiedzi
     * @param onFailure     Funkcja, która wykona się po braku odpowiedzi lub błędzie
     * @param keepAlive
     * @param commandName {null|"GET_HMIS_CONFIGURATION"}
     * @param customTopic {null|string}
     */
    startSendingDeviceState(dev, onSuccess = onGetFullDevStateSuccess, onFailure = onGetFullDevStateFailure, {
        keepAlive = false,
        commandName = null,
        topic = null,
        transformMessage = null
    } = {}) {
        const { ClientID, LocalUserID, GatewayID, DevID, isValid } = getIOTAttributes(dev);
        console.log("startSendingDeviceState");
        if (isValid) {
            const sendTopic = topic ? template(topic, {
                Stage: STAGE.slice(0, -1),
                ClientID,
                GatewayID,
                LocalUserID
            }) : `${STAGE}devices/state/${ClientID}/${GatewayID}/${LocalUserID}`;
            const id = myID();
            let command = {
                MsgId: id,
                PktType: MessageTypes.REQUEST,
                DeviceId: GatewayID,
                RTime: new Date().getTime(),
                Command: [commandName ?? MessageCommands.GET_FULL_DEV_STATE],
                CData: { DeviceIDs: isArray(DevID) ? DevID : [DevID] }
            };
            if (transformMessage) {
                command = transformMessage(command);
            }
            store.dispatch(
                setShadowLoading({
                    DevID: command.CData.DeviceIDs,
                    status: true,
                })
            );
            const stopLoading = (message) => {
                if (!message?.CData?.DeviceIDs) return;
                store.dispatch(
                    setShadowLoading({
                        DevID: message.CData.DeviceIDs,
                        status: false,
                    })
                );
            };
            const startLoading = (message) => {
                if (!message?.CData?.DeviceIDs) return;
                store.dispatch(
                    setShadowLoading({
                        DevID: message.CData.DeviceIDs,
                        status: true,
                    }));
            };
            const onSuccessWrapped = (m) => {
                stopLoading(command);
                if (isFunction(onSuccess)) onSuccess(m);
            };
            const onFailureWrapped = (m) => {
                stopLoading(command);
                if (isFunction(onFailure)) onFailure(m);
            };
            this.createAndAddMessageToQueue(
                sendTopic,
                command,
                null,
                onSuccessWrapped,
                onFailureWrapped,
                startLoading
            );
            if (keepAlive) {
                let interval = setInterval(() => {
                    command.MsgId = myID();
                    command.RTime = new Date().getTime();
                    this.createAndAddMessageToQueue(
                        sendTopic,
                        command,
                        null,
                        stopLoading,
                        stopLoading,
                        startLoading
                    );
                }, IntervalTime);
                this.intervalMap.set(id, interval);
            }
            // console.error("WYSYLAM");
            return id;
        } else {
            onFailure(new Error('No MQTT params'));
        }
    }

    removeFromInterval(messageId) {
        console.log("removing intervals for -> ", messageId);
        const msgIds = isString(messageId)
            ? [messageId]
            : isArray(messageId)
                ? messageId
                : [];
        msgIds.forEach((MsgId) => {
            const interval = this.intervalMap.get(MsgId);
            if (interval) {
                clearInterval(interval);
                this.intervalMap.delete(MsgId);
            }
        });
    }

    removeAllIntervals() {
        const msgIdList = this.intervalMap.keys();
        for (let msgId of msgIdList) {
            this.removeFromInterval(msgId);
        }
    }

    /**
     * Metoda subskrybuje do kanalu od response a nastepnie wysyla polecenie zakonczenie wysylania aktualnego stanu
     * oraz czysci interval a nastepnie odsubskrbowuje kanal jezeli nie ma zadnego urzadzenia proszacego o aktualny stan
     * @param ClientID      ID klienta
     * @param LocalUserID   ID uzytkownika
     * @param GatewayID     ID gateway (raspberry)
     * @param DeviceIDs
     */
    stopSendingDeviceState(ClientID, LocalUserID, GatewayID, DeviceIDs = []) {
        try {
            let command = {
                MsgId: myID(),
                PktType: MessageTypes.REQUEST,
                DeviceId: GatewayID,
                RTime: new Date().getTime(),
                Command: [MessageCommands.STOP_SENDING_DEV_STATE],
                CData: { DeviceIDs: DeviceIDs }
            };
            // let sendTopic = `${STAGE}devices/state/${ClientID}/${GatewayID}/${LocalUserID}`;
            let sendTopic = `${STAGE}devices/commands/${ClientID}/${GatewayID}/${LocalUserID}`;
            this.createAndAddMessageToQueue(sendTopic, command);
            // console.log(this.deviceStateMap);
            // let interval = this.deviceStateMap.get(DeviceID);
            // console.log(interval);
            // if (interval) {
            //     clearInterval(interval);
            //     this.deviceStateMap.delete(DeviceID);
            // }
            // if (this.deviceStateMap.size === 0) {
            //     let topic = `${STAGE}devices/state/${ClientID}/${GatewayID}/${LocalUserID}`;
            //     this.unsubscribeTopic(topic);
            // }
        } catch (e) {
            console.error("ERROR: " + e);
        }
    }

    startSendingFeedingState(dev, { onSuccess, onFailure, keepAlive = false } = {}) {
        const { ClientID, LocalUserID, GatewayID, DevID, isValid } = getIOTAttributes(dev);
        if (isValid) {
            let sendTopic = `${STAGE}devices/state/${ClientID}/${GatewayID}/${LocalUserID}`;
            const id = myID();
            let command = {
                MsgId: id,
                PktType: MessageTypes.REQUEST,
                DeviceId: GatewayID,
                RTime: new Date().getTime(),
                Command: [MessageCommands.GET_FEED_STATE],
                CData: { DeviceIDs: isArray(DevID) ? DevID : [DevID] }
            };
            this.createAndAddMessageToQueue(
                sendTopic,
                command,
                null,
                onSuccess,
                onFailure
            );
            if (keepAlive) {
                let interval = setInterval(() => {
                    command.MsgId = myID();
                    command.RTime = new Date().getTime();
                    this.createAndAddMessageToQueue(
                        sendTopic,
                        command,
                        null,
                        null,
                        null
                    );
                }, IntervalTime);
                this.intervalMap.set(id, interval);
            }
            return id;
        } else {
            if (isFunction(onFailure)) {
                onFailure();
            }
        }
    }

    sendMapState(gateway, { Locations = [], Devices = [] } = {}, { onSuccess, onFailure } = {}) {
        const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(gateway);
        if (isValid) {
            let sendTopic = `${STAGE}devices/state/${ClientID}/${GatewayID}/${LocalUserID}`;
            const id = myID();
            let command = {
                MsgId: id,
                PktType: MessageTypes.REQUEST,
                DeviceId: GatewayID,
                RTime: new Date().getTime(),
                Command: [GatawayCommandTypes.GET_DATA_BY_LOCATION],
                CData: { Locations, Devices }
            };
            this.createAndAddMessageToQueue(
                sendTopic,
                command,
                null,
                onSuccess,
                onFailure
            );
            return id;
        } else {
            if (isFunction(onFailure)) {
                onFailure(new Error('No MQTT params'));
            }
        }
    }

    startSendingFeedingStateRFID(dev, chamberId, { onSuccess, onFailure, keepAlive = false } = {}) {
        const { ClientID, LocalUserID, GatewayID, isValid } = getIOTAttributes(dev);
        if (isValid) {
            let sendTopic = `${STAGE}devices/state/${ClientID}/${GatewayID}/${LocalUserID}`;
            const id = myID();
            let command = {
                MsgId: id,
                PktType: MessageTypes.REQUEST,
                DeviceId: GatewayID,
                RTime: new Date().getTime(),
                Command: [MessageCommands.GET_FEED_RFID_STATE],
                CData: { PlcmntID: chamberId }
            };
            this.createAndAddMessageToQueue(
                sendTopic,
                command,
                null,
                onSuccess,
                onFailure
            );
            if (keepAlive) {
                let interval = setInterval(() => {
                    command.MsgId = myID();
                    command.RTime = new Date().getTime();
                    this.createAndAddMessageToQueue(
                        sendTopic,
                        command,
                        null,
                        null,
                        null
                    );
                }, IntervalTime);
                this.intervalMap.set(id, interval);
            }
            return id;
        } else {
            if (isFunction(onFailure)) {
                onFailure(new Error('No MQTT params'));
            }
        }
    }

    /**
     * Metoda wysylajaca zapytanie o zagregowane dane urzadzenia
     * @param dev
     * @param dataType
     */
    sendRequestForAggregatedData(dev, { dataType } = {}) {
        // console.log(dev);
        const { ClientID, LocalUserID, GatewayID, DevID, isValid } = getIOTAttributes(dev);
        if (isValid) {
            let sendTopic = `${STAGE}devices/state/${ClientID}/${GatewayID}/${LocalUserID}`;
            const deviceIds = isArray(DevID) ? DevID : [DevID];
            let command = {
                MsgId: myID(),
                PktType: MessageTypes.REQUEST,
                DeviceId: GatewayID,
                RTime: new Date().getTime(),
                Command: [MessageCommands.GET_AGGREGATED_DATA],
                CData: { DeviceIDs: dataType ? deviceIds.map((id) => `${dataType}#${id}`) : deviceIds }
            };

            function fetchNewestAggData() {
                for (let deviceId of deviceIds) {
                    const device = devicesDB.getDeviceByID(deviceId);
                    if (device) {
                        if (this.type === "error") {
                            store.dispatch(getAggregatedData(device, { dataType }));
                        } else if (!enableMQTTAggregatedData({ DeTy: device.DevType })) {
                            // Dane po MQTT nie zawsze są poprawne, np. dodajemy jakieś rzeczy na API
                            // w tym wypadku musimy odpytać dynamo nawet na handlerze successu
                            setTimeout(() => {
                                store.dispatch(getAggregatedData(device, { dataType }));
                            }, 2000); // musimy chwile poczekać, aż dane z GW pojawią się na bazie
                        }
                    }
                }
            }

            this.createAndAddMessageToQueue(
                sendTopic,
                command,
                null,
                fetchNewestAggData.bind({ type: "success" }),
                fetchNewestAggData.bind({ type: "error" })
            );
        }
    }

    /**
     * Metoda tworzaca strukture wiadomosci i dodaje do kolejki
     * @param ClientID      ID klienta
     * @param GatewayID     ID gateway
     * @param LocalUserID   ID zalogowanego uzytkownika
     * @param DeviceID      ID urzadzenia
     * @param data          Informacje do przeslania
     * @param Command   Typ komendy np. GET_CONFIGURATION
     * @param notification  Notyfikacja, ktora bedzie wyswietlona w formacie {loading: loadingNotif, success: successNotif, error: errorNotif, DevID: id urzadzenia}
     * @param onSuccess     Funkcja, ktora bedzie wywolana jezeli zapytanie sie uda (msg) => void
     * @param onError       Funkcja, ktora bedzie wywoalana jezeli zapytanie sie nie uda (error) => void
     * @param onSend
     * @param FarmID
     * @param isState
     * @returns {string}    ID wysłanej komendy
     */
    createAndSendMessageObject(
        ClientID,
        GatewayID,
        LocalUserID,
        DeviceID,
        Command,
        data = undefined,
        notification = undefined,
        onSuccess = undefined,
        onError = undefined,
        onSend = undefined,
        FarmID = null,
        isState = false,
        useAPI = true
    ) {
        let id = myID();
        let tmp = {
            MsgId: id,
            PktType: MessageTypes.REQUEST,
            DeviceId: DeviceID,
            RTime: new Date().getTime(),
            Command: Array.isArray(Command) ? Command : [Command],
            CData: {},
            Priority: 69,
        };
        if (data) {
            // tmp.CData = this.compressData(data);
            tmp.CData = data;
        }
        let topic = isState
            ? `${STAGE}devices/state/${ClientID}/${GatewayID}/${LocalUserID}`
            : `${STAGE}devices/commands/${ClientID}/${GatewayID}/${LocalUserID}`;
        this.createAndAddMessageToQueue(
            topic,
            tmp,
            notification,
            onSuccess,
            onError,
            onSend,
            FarmID,
            useAPI
        );
        return id;
    }

    /**
     * Metoda tworzaca strukture wiadomosci i dodaje do kolejki
     * @param ClientID      ID klienta
     * @param GatewayID     ID gateway
     * @param LocalUserID   ID zalogowanego uzytkownika
     * @param DeviceID      ID urzadzenia
     * @param data          Informacje do przeslania
     * @param Command   Typ komendy np. GET_CONFIGURATION
     * @param notification  Notyfikacja, ktora bedzie wyswietlona w formacie {loading: loadingNotif, success: successNotif, error: errorNotif, DevID: id urzadzenia}
     * @param onSuccess     Funkcja, ktora bedzie wywolana jezeli zapytanie sie uda (msg) => void
     * @param onError       Funkcja, ktora bedzie wywoalana jezeli zapytanie sie nie uda (error) => void
     * @returns {string}    ID wysłanej komendy
     */
    createAndSendMessageObjectToStateTopic(
        ClientID,
        GatewayID,
        LocalUserID,
        DeviceID,
        Command,
        data = undefined,
        notification = undefined,
        onSuccess = undefined,
        onError = undefined
    ) {
        let id = myID();
        let tmp = {
            MsgId: id,
            PktType: MessageTypes.REQUEST,
            DeviceId: DeviceID,
            RTime: new Date().getTime(),
            Command: Array.isArray(Command) ? Command : [Command],
            CData: {},
        };
        if (data) {
            // tmp.CData = this.compressData(data);
            tmp.CData = data;
        }
        let topic = `${STAGE}devices/state/${ClientID}/${GatewayID}/${LocalUserID}`;
        this.createAndAddMessageToQueue(
            topic,
            tmp,
            notification,
            onSuccess,
            onError
        );
        return id;
    }

    createAndSendDeviceTreeMessage(ClientID, LocalUserID, GatewayID, data) {
        let id = myID();
        // console.log(data);
        let tmp = {
            MsgId: id,
            PktType: MessageTypes.REQUEST,
            RTime: new Date().getTime(),
            Command: [MessageCommands.UPDATE_DEVICES],
            CData: data,
            DeviceId: GatewayID,
        };
        // console.log(tmp);
        let topic = `${STAGE}devices/commands/${ClientID}/${GatewayID}/${LocalUserID}`;
        // console.log(topic);
        this.createAndAddMessageToQueue(topic, tmp);
        return id;
    }

    createAndSendDeviceTreeGetMessage(
        ClientID,
        LocalUserID,
        GatewayID,
        onSuccess,
        onFailure
    ) {
        let id = myID();
        let tmp = {
            MsgId: id,
            PktType: MessageTypes.REQUEST,
            RTime: new Date().getTime(),
            Command: [MessageCommands.GET_DEVICES],
            CData: {},
            DeviceId: GatewayID,
        };
        let topic = `${STAGE}devices/commands/${ClientID}/${GatewayID}/${LocalUserID}`;
        this.createAndAddMessageToQueue(topic, tmp, null, onSuccess, onFailure);
        return id;
    }

    onLocalAuthConnectedToMQTT = (topic) => () => {
        store.dispatch(setGlobalLoadingText(i18next.t("serviceAuth")));
        store.dispatch(setLocalConnection(true));
        this.subscribeTopic(topic);
    };

    async localAuthUser(email, password, ipAddress, onSuccess, onError) {
        this.localGWData = ipAddress;
        let topic = `${STAGE}devices/auth/${ipAddress.clientID}/${ipAddress.tName}`;
        if (!this.client) {
            console.log("Create local clieant");
            await this.createLocalClient(
                ipAddress.address,
                this.onLocalAuthConnectedToMQTT(topic)
            );
        }
        let id = myID();
        let tmp = {
            MsgId: id,
            PktType: MessageTypes.REQUEST,
            RTime: new Date().getTime(),
            Command: [MessageCommands.AUTH_USER],
            CData: {
                login: email,
                password: password ? password : undefined,
            },
        };
        this.createAndAddMessageToQueue(
            topic,
            tmp,
            null,
            (msg) => {
                this.isAuthenticatingToLocal = false;
                onSuccess(msg);
            },
            (error, msg) => {
                this.isAuthenticatingToLocal = false;
                onError(error, msg);
            },
            () => {
                this.isAuthenticatingToLocal = true;
            }
        );
        return id;
    }

    getNotificationStatus(DevID, message) {
        try {
            let status;
            if (!isNil(message.CAnsw.status)) {
                status = message.CAnsw.status;
            } else if (DevID && message.CAnsw[DevID]) {
                status = message.CAnsw[DevID].status;
            }
            console.log(status);
            if (status instanceof Array) {
                console.log("StATUS", message);
                switch (message.Command[0]) {
                    case DispenserDriverCommandTypes.STOP_MOTORS:
                    case DispenserDriverCommandTypes.START_MOTORS: {
                        let tmpStatus = true;
                        for (let value of message.CData) {
                            if (status[value.number - 1] === 0)
                                tmpStatus = false;
                        }
                        status = tmpStatus
                            ? IoTResponseStatus.SUCCESS
                            : IoTResponseStatus.COMMAND_REJECTED;
                    }
                        break;
                    case DispenserDriverCommandTypes.SET_FORCE_FEEDING:
                    case DispenserDriverCommandTypes.SET_SKIP_DOSES: {
                        let tmpStatus = true;
                        if (isArray(message.CData)) {
                            for (let value of message.CData) {
                                if (status[value.number - 1] !== 0)
                                    tmpStatus = false;
                            }
                        } else {
                            if (typeof status !== "number") {
                                if (status[message.CData.number - 1] !== 0)
                                    tmpStatus = false;
                            }
                        }

                        status = tmpStatus
                            ? IoTResponseStatus.SUCCESS
                            : IoTResponseStatus.COMMAND_REJECTED;
                    }
                        break;
                    default:
                        status = IoTResponseStatus.COMMAND_REJECTED;
                }
            }
            return status;
        } catch (e) {
            console.error(e);
            return IoTResponseStatus.NO_STATUS;
        }
    }

    sendBugsnagReportFromDevice(status, message) {
        const { user: { user: { ClientID } } } = store.getState();
        if (ClientID !== "TestNewRole") {
            bugsnagClient.notify(
                new Error(`${message.Command[0]} (error)`),
                (event) => {
                    event.addMetadata("feedback", {
                        status: status,
                        user: ClientID,
                        CAnsw: get(message, "CAnsw", "No CAnsw"),
                        CData: get(message, "CData", "No CData"),
                        deviceID: message.DeviceId,
                    });
                }
            );
        }
    }

    _getDeviceIndexByMessage(message) {
        try {
            const command = message.Command[0];
            switch (command) {
                case DispenserDriverCommandTypes.SET_FORCE_FEEDING: {
                    return message.CData.number - 1;
                }
                default:
                    return null;
            }
        } catch (err) {
            return null;
        }
    }

    changeNotification(message, notification) {
        // console.log("changeNotification", message, notification);
        // let notifications = store.getState().notifications;
        // let notif = notifications.filter(item => item.id === message.MsgID)[0];
        // console.log(notif);
        // if(notif){
        //     notif.dismissible = true;
        //     notif.dismissAfter = 5000;
        //TODO tłumaczenia do odpowiedzi
        let overrideMessage;
        const statusForDevice = {};
        try {
            let notif = {
                id: notification.id,
                ...notification.loading,
                dismissible: true,
                dismissAfter: 5000,
            };
            let status;
            if (isArray(notification.DevID)) {
                let okCounter = 0;
                let allDevices = notification.DevID.length;
                for (let DevID of notification.DevID) {
                    let st = this.getNotificationStatus(DevID, message);
                    okCounter += st === IoTResponseStatus.SUCCESS ? 1 : 0;
                    statusForDevice[st] = !statusForDevice[st]
                        ? [DevID]
                        : [...statusForDevice[st], DevID];
                }
                console.log("stat4dev", statusForDevice);
                if (okCounter === allDevices) {
                    status = IoTResponseStatus.SUCCESS;
                } else {
                    // jesli odpowiedz jest jednego typu to ja ustawiamy
                    if (
                        Object.keys(statusForDevice).length === 1 &&
                        +Object.keys(statusForDevice)[0] ===
                        IoTResponseStatus.SUCCESS
                    ) {
                        status = +Object.keys(statusForDevice)[0];
                    } else {
                        // rozbicie odpowiedzi
                        overrideMessage = "<div>";
                        for (let [sta, devices] of Object.entries(
                            statusForDevice
                        )) {
                            overrideMessage += `
                                <strong>${getMessageForStatus(+sta)}</strong>: 
                                <ol class="ps-1">
                                ${devices.map(
                                (o) =>
                                    `<li>${deviceIdToName(
                                        o,
                                        this._getDeviceIndexByMessage(
                                            message
                                        )
                                    )}</li>`
                            )}
                                </ol>`;
                        }
                        overrideMessage += "</div>";
                    }
                }
            } else {
                status = this.getNotificationStatus(
                    notification.DevID,
                    message
                );
                if (status !== IoTResponseStatus.SUCCESS) {
                    overrideMessage = `${getMessageForStatus(
                        status
                    )} <i>${deviceIdToName(
                        notification.DevID,
                        this._getDeviceIndexByMessage(message)
                    )}</i>`;
                }
            }
            console.log(status);
            let waitedMessage = this.waitedSetMessage.find(
                (item) => item.message.MsgId === message.MsgId
            );
            switch (status) {
                case IoTResponseStatus.SUCCESS:
                    notif = {
                        ...notif,
                        ...notification.success,
                    };
                    if (waitedMessage.onSuccess)
                        waitedMessage.onSuccess(message);
                    break;
                case IoTResponseStatus.REQUEST_PARSER_ERROR:
                    notif.message = i18next.t("IOT.messageParserError");
                    notif.status = "error";
                    this.sendBugsnagReportFromDevice(
                        IoTResponseStatus.REQUEST_PARSER_ERROR,
                        message
                    );
                    break;
                case IoTResponseStatus.COMMAND_REJECTED:
                    notif.message = i18next.t("IOT.deviceRejectedQuery");
                    notif.status = "error";
                    this.sendBugsnagReportFromDevice(
                        IoTResponseStatus.COMMAND_REJECTED,
                        message
                    );
                    break;
                case IoTResponseStatus.NO_RESPONSE_FROM_DEVICE:
                    notif.message = i18next.t("IOT.noResponseFromDevice");
                    notif.status = "error";
                    this.sendBugsnagReportFromDevice(
                        IoTResponseStatus.NO_RESPONSE_FROM_DEVICE,
                        message
                    );
                    break;
                case IoTResponseStatus.DATA_NOT_READY:
                    notif.message = i18next.t(
                        "IOT.noDownloadedDataOnTheDeviceWaitAMomentAndRepeatTheQuery"
                    );
                    notif.status = "error";
                    break;
                case IoTResponseStatus.RESPONSE_PARSER_ERROR:
                    notif.message = i18next.t("IOT.returningParserError");
                    notif.status = "error";
                    break;
                case IoTResponseStatus.NO_STATUS:
                    notif.message = i18next.t("IOT.responseProcessingError");
                    notif.status = "error";
                    break;
                case IoTResponseStatus.UNAUTHORIZED:
                    notif.message = i18next.t("IOT.noPrivileges");
                    notif.status = "error";
                    break;
                case IoTResponseStatus.DEVICE_CURRENTLY_FEEDING:
                    notif.message = i18next.t("IOT.alreadyFeeding");
                    notif.status = "error";
                    break;
                case IoTResponseStatus.DEVICE_OFFLINE:
                    notif.message = i18next.t("IOT.deviceOffline");
                    notif.status = "error";
                    break;
                default:
                    notif.message = getMessageForStatus(status);
                    notif.status = "warning";
                    break;
            }
            if (status !== IoTResponseStatus.SUCCESS && waitedMessage.onError) {
                waitedMessage.onError(null, message);
            }
            if (overrideMessage) {
                notif.message = overrideMessage;
                notif.status = statusForDevice[IoTResponseStatus.SUCCESS]
                    ? "warning"
                    : "error";
                notif.dismissible = true;
                notif.dismissAfter = isArray(notification.DevID) ? 20000 : 5000;
                notif.allowHTML = true;
            }
            store.dispatch(notify(notif));
        } catch (e) {
            console.error(e);
            let waitedMessage = this.waitedSetMessage.find(
                (item) => item.message.MsgId === message.MsgId
            );
            if (waitedMessage) waitedMessage.callback(e, null);
        }
    }

    checkGetConfiguration(result, id) {
        if (result.CAnsw.config) return true;
        try {
            return !!result.CAnsw[id].configuration;
        } catch (e) {
            return !!result.CAnsw.configuration;
        }
    }

    checkResult(result, { isStateMessage = false } = {}) {
        console.log("check result");
        let waitedMessage = this.waitedSetMessage.find(
            (item) => item.message.MsgId === result.MsgId
        );
        if (isStateMessage && !waitedMessage) {
            waitedMessage = {};
        }
        console.log(waitedMessage);
        if (waitedMessage.notification) {
            this.changeNotification(result, waitedMessage.notification);
        } else {
            if (!Array.isArray(result.Command))
                result.Command = [result.Command];
            // result.Command.map(command => {
            for (let command of result.Command) {
                switch (command) {
                    case MessageCommands.GET_DEVICES:
                        if (waitedMessage.onSuccess)
                            waitedMessage.onSuccess(result);
                        break;
                    case DispenserNRFCommandTypes.GET_HISTORY_STANDARD:
                    case DispenserNRFCommandTypes.GET_PIG:
                        if (
                            result.CAnsw &&
                            result.CAnsw[result.DeviceId] !== undefined &&
                            !result.CAnsw[result.DeviceId].status
                        ) {
                            Object.keys(result.CAnsw).map((key) => {
                                let data = new Map();
                                result.CAnsw[key].RTime = result.RTime;
                                data.set(key, result.CAnsw[key]);
                                store.dispatch(changeShadowState(data));
                                this.counters.fCounter++;
                                return key;
                            });
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case DispenserNRFCommandTypes.GET_LOGS:
                        if (
                            result.CAnsw &&
                            result.CAnsw[result.DeviceId] !== undefined
                        ) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case GatawayCommandTypes.GET_GATEWAY_TIME:
                        if (result.CAnsw && result.CAnsw.time !== undefined) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case ClimateCommandTypes.GET_AMBIENT_CONDITIONS:
                        if (result?.CAnsw?.ambientConditions) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case MessageCommands.GET_FULL_DEV_STATE:
                        if (
                            result.CAnsw &&
                            Object.keys(result.CAnsw).length > 0
                        ) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case GatawayCommandTypes.GET_DATA_BY_LOCATION:
                        if (
                            result.CAnsw &&
                            !result.CAnsw.hasOwnProperty("status")
                        ) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case MessageCommands.GET_NRF_FEEDERS:
                        if (result.CAnsw && Array.isArray(result.CAnsw)) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case GatawayCommandTypes.PING:
                        if (result.CAnsw && result.CAnsw.status === 2) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case SeparationCageCommandTypes.GET_SENSORS_AND_VALVES:
                        if (
                            result.CAnsw &&
                            result.CAnsw.sensors &&
                            result.CAnsw.valves
                        ) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case ClimateDriverCommandTypes.GET_CONFIGURATION:
                        let devIDs = Array.isArray(result.DeviceId)
                            ? result.DeviceId
                            : [result.DeviceId];
                        let hasAll = true;
                        for (let id of devIDs) {
                            if (!this.checkGetConfiguration(result, id))
                                hasAll = false;
                        }
                        if (hasAll) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case GatawayCommandTypes.GET_NRF_STATS:
                        if (result.CAnsw && result.CAnsw.stats) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case MessageCommands.AUTH_USER:
                        if (result.CAnsw && !result.CAnsw.Code) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case BridgeCommandTypes.B_INFO:
                        if (
                            result.CAnsw?.b_info
                        ) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case BridgeCommandTypes.B_BOOT_INFO:
                        if (
                            result.CAnsw?.boot_info
                        ) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case BridgeCommandTypes.B_BOOT_INFO_NRF:
                        if (
                            result.CAnsw?.boot_info_nrf
                        ) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case BridgeCommandTypes.B_NRF_STAT:
                        if (
                            result.CAnsw?.nrf_stat
                        ) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case BridgeCommandTypes.B_485_TOUT_R:
                        if (
                            result.CAnsw?.rs485_tout
                        ) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case BridgeCommandTypes.B_GET_SCAN_ADDR:
                        if (
                            result.CAnsw?.scan_addr
                        ) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case GatawayCommandTypes.GET_QUEUES_LAST_SUCCESS:
                        if (result.CAnsw && result.CAnsw.queues) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case GatawayCommandTypes.GET_PIGS_DATA:
                        if (
                            result.CAnsw &&
                            (result.CAnsw.curveCfg || result.CAnsw.pigs)
                        ) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case GatawayCommandTypes.GET_ASCII_CLIMATE_TESTING_DATA:
                        if (result.CAnsw && result.CAnsw.data) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case MessageCommands.GET_FEED_RFID_STATE: {
                        if (result.CAnsw && !result.CAnsw.status) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    }
                    case GatawayCommandTypes.SET_CONFIRM_ALARM:
                    case GatawayCommandTypes.GET_ALL_ALARMS_BY_CODE:
                    case GatawayCommandTypes.GET_ALL_ONGOING_ALARMS_BY_CODE:
                    case GatawayCommandTypes.GET_ALL_ALARMS:
                    case GatawayCommandTypes.GET_ALL_ONGOING_ALARMS: {
                        if (waitedMessage.onSuccess)
                            waitedMessage.onSuccess(result);
                        break;
                    }
                    case GatawayCommandTypes.GET_FEEDING_DATA_FOR_PIG:
                    case GatawayCommandTypes.GET_USAGE_FOR_PIG:
                        console.log(
                            result.CAnsw.result,
                            result.CAnsw.result !== null
                        );
                        if (result.CAnsw && Array.isArray(result.CAnsw)) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case MessageCommands.REFRESH_TOKEN:
                        if (result.CAnsw && result.CAnsw.token) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case DispenserDriverCommandTypes.SET_SKIP_DOSES: {
                        if (result.CAnsw) {
                            if (Array.isArray(result.CAnsw.status)) {
                                let isSuccess = true;
                                for (let item of result.CAnsw.status) {
                                    if (item) isSuccess = false;
                                }
                                if (isSuccess) {
                                    if (waitedMessage.onSuccess)
                                        waitedMessage.onSuccess(result);
                                } else {
                                    if (waitedMessage.onError)
                                        waitedMessage.onError(
                                            null,
                                            waitedMessage.message
                                        );
                                }
                            } else if (result.CAnsw.status === 2) {
                                if (waitedMessage.onSuccess)
                                    waitedMessage.onSuccess(result);
                            } else {
                                if (waitedMessage.onError)
                                    waitedMessage.onError(
                                        null,
                                        waitedMessage.message
                                    );
                            }
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    }
                    case GatawayCommandTypes.GET_DEVICE_LOGS: {
                        if (result.CAnsw && result.CAnsw.logs) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    }
                    case DispenserNRFCommandTypes.GET_SERIAL_NUMBER: {
                        if (result.CAnsw?.serialNumber.f103) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    }
                    case GatawayCommandTypes.GET_GW_ALERTS_UNGROUPED: {
                        if (Array.isArray(result.CAnsw)) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    }
                    case ClimateCommandTypes.GET_CLOCK_MENU: {
                        if (
                            result.CAnsw.hasOwnProperty("climateCurve") &&
                            result.CAnsw.hasOwnProperty("service")
                        ) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    }
                    case GatawayCommandTypes.GET_PROGRAM_DETAILS:
                    case GatawayCommandTypes.GET_UPLOAD_STATUS: {
                        if (
                            Object.keys(result.CAnsw).length > 0 &&
                            !result.CAnsw.status
                        ) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    }
                    case GatawayCommandTypes.GET_RFID_PIG_HISTORY:
                        if (
                            isObject(result.CAnsw) &&
                            isArray(result.CAnsw.history)
                        ) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result.CAnsw.history);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case GatawayCommandTypes.GET_GATEWAY:
                        if (result.CAnsw?.DevID) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case GatawayCommandTypes.GET_RPI_IP_ADDRESSES:
                    case DispenserNRFCommandTypes.GET_IPSUM_DETAILS_PASSES:
                    case DispenserNRFCommandTypes.GET_IPSUM_RFIDS:
                        console.log(result);
                        if (Array.isArray(result.CAnsw)) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case DispenserNRFCommandTypes.GET_IPSUM_PASSES:
                    case DispenserNRFCommandTypes.GET_IPSUM_RFID_INFO:
                        if (result.CAnsw?.passes && result.CAnsw?.details) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    case UniversalBoardCommandTypes.U_READ_RAM: {
                        if (result?.CAnsw?.readRAM) {
                            // check for hex string
                            if (isString(result.CAnsw.readRAM)) {
                                const p = getParser();
                                result.CAnsw.readRAM = [
                                    ...p.utils.hexString2Buf(
                                        result.CAnsw.readRAM
                                    ),
                                ];
                            }
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result.CAnsw);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    }
                    case GatawayCommandTypes.GET_OFFLINE_AGGREGATED_DATA: {
                        if (result?.CAnsw?.data) {
                            if (waitedMessage.onSuccess)
                                waitedMessage.onSuccess(result.CAnsw);
                        } else {
                            if (waitedMessage.onError)
                                waitedMessage.onError(
                                    null,
                                    waitedMessage.message
                                );
                        }
                        break;
                    }
                    case AlarmControlPanelCommandTypes.GET_HMIS_CONFIGURATION: {
                        console.log("JKDIJSDAIOASDJIASDJSDOAIJASIDO", result);
                        if (isNil(result.CAnsw.status) || result.CAnsw.status === 2) {
                            const map = new Map();
                            const shadow = { ...result.CAnsw };
                            shadow.metadata = {};
                            const now = +new Date();
                            for (let key in result.CAnsw) {
                                if (!result.CAnsw.hasOwnProperty(key)) continue;
                                shadow.metadata[key] = result.RTime ?? now;
                            }
                            map.set(result.DeviceId, shadow);
                            store.dispatch(changeShadowState(map));
                            if (waitedMessage?.onSuccess) {
                                waitedMessage.onSuccess(result.CAnsw);
                            }
                        } else if (waitedMessage?.onError) {
                            waitedMessage.onError(
                                null,
                                waitedMessage.message
                            );
                        }
                        break;
                    }
                    default:
                        if (result.CAnsw) {
                            if (Array.isArray(result.DeviceId)) {
                                let failed = false;
                                for (let DevID of result.DeviceId) {
                                    if (result.CAnsw[DevID]?.status !== 2) {
                                        failed = true;
                                        break;
                                    }
                                }
                                if (failed) {
                                    if (waitedMessage.onError)
                                        waitedMessage.onError(
                                            null,
                                            waitedMessage.message
                                        );
                                } else {
                                    if (waitedMessage.onSuccess)
                                        waitedMessage.onSuccess(result);
                                }
                            } else if (result.CAnsw.status) {
                                if (result.CAnsw.status !== 2) {
                                    if (waitedMessage.onError)
                                        waitedMessage.onError(
                                            null,
                                            waitedMessage.message
                                        );
                                } else {
                                    if (waitedMessage.onSuccess)
                                        waitedMessage.onSuccess(result);
                                }
                            } else {
                                if (
                                    result.CAnsw[result.DeviceId]?.status !== 2
                                ) {
                                    if (waitedMessage.onError)
                                        waitedMessage.onError(
                                            null,
                                            waitedMessage.message
                                        );
                                } else {
                                    if (waitedMessage.onSuccess)
                                        waitedMessage.onSuccess(result);
                                }
                            }
                        }
                        console.warn(
                            "Brak notyfikacji i nie rozpoznano komendy"
                        );
                }
            }
        }
        this.waitedSetMessage = this.waitedSetMessage.filter(
            (item) => item.message.MsgId !== result.MsgId
        );
    }

    checkCommandByType(commands, type) {
        try {
            let cmnds = Array.isArray(commands) ? commands : [commands];
            for (let i = 0; i < cmnds.length; i++) {
                if (cmnds[i] === type) {
                    return true;
                }
            }
            return false;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    /**
     * Zwraca czy oczekiwano na daną komendę
     * @param commands
     * @returns {boolean}
     */
    checkCommands(commands) {
        try {
            let isWaitedMessage = true;
            for (let i = 0; i < commands.length; i++) {
                if (
                    !commands[i].startsWith("SET") &&
                    !IsSpecialMessage(commands[i])
                )
                    isWaitedMessage = false;
            }
            return isWaitedMessage;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    checkIfWaitedForThisMessage(message) {
        return (
            this.waitedSetMessage.filter(
                (item) => item.message.MsgId === message.MsgId
            ).length > 0
        );
    }

    /**
     *
     * @param topic example devices/state/WesstronNewiot1/GW_MAR_18_08_14_093608856
     * @param message
     */
    onStateMessageArrived(topic, message) {
        console.warn("NEW STATE MESSAGE", topic, message);
        try {
            if (message.PktType === MessageTypes.BIG_RESPONSE) {
                // message.CAnsw = this.decompressData(message.CAnsw);
                // console.log(ClientID, GatewayID, LocalUserID);
                //sprawdzenie czy odpowiedz jest obiektem a nie np stringiem typu 'nie znaleziono urzadzenia: blabla'
                if (isObject(message.CAnsw) && !message.CAnsw.status) {
                    let data = new Map();
                    for (let DevID in message.CAnsw) {
                        const potentialData = message.CAnsw[DevID];
                        if (isNil(potentialData)) continue;
                        if (!potentialData?.status) {
                            data.set(DevID, potentialData);
                        }
                    }
                    if (!isEmpty(data)) {
                        if (
                            this.checkCommandByType(
                                message.Command,
                                MessageCommands.GET_FULL_DEV_STATE
                            )
                        ) {
                            store.dispatch(changeShadowState(data));
                            this.counters.fCounter++;
                        } else if (
                            this.checkCommandByType(
                                message.Command,
                                MessageCommands.GET_FULL_DEV_STATE_DELTA
                            )
                        ) {
                            store.dispatch(changeShadowState(data, true));
                            this.counters.dCounter++;
                            let noShadowIDs = [];
                            let state = store.getState();
                            let shadows = state.shadows.shadows;
                            const getDeviceByID = (_id) =>
                                state.farmDevices.devices.find(
                                    (d) => d.DevID === _id
                                );
                            for (let DevID in message.CAnsw) {
                                if (!shadows.get(DevID)) {
                                    // condition prevents asking for devices' data that we dont have access to
                                    if (getDeviceByID(DevID)) {
                                        noShadowIDs.push(DevID);
                                    }
                                }
                            }
                            if (noShadowIDs.length > 0) {
                                this.startSendingDeviceState(noShadowIDs);
                            }
                        } else if (
                            this.checkCommandByType(
                                message.Command,
                                DispenserNRFCommandTypes.GET_DAILY_USAGE
                            )
                        ) {
                            store.dispatch(changeAnimalShadow(data));
                        } else if (
                            this.checkCommandByType(
                                message.Command,
                                DispenserNRFCommandTypes.GET_DAILY_USAGE_DELTA
                            )
                        ) {
                            store.dispatch(changeAnimalShadow(data, true));
                        } else if (
                            this.checkCommandByType(
                                message.Command,
                                GatawayCommandTypes.ADDRESS_DELTA
                            )
                        ) {
                            store.dispatch(
                                changeAddressingState(message.CAnsw)
                            );
                        } else if (
                            this.checkCommandByType(
                                message.Command,
                                MessageCommands.GET_FEED_STATE
                            )
                        ) {
                            store.dispatch(getFeedState(data));
                        } else if (
                            this.checkCommandByType(
                                message.Command,
                                MessageCommands.GET_FEED_RFID_STATE
                            )
                        ) {
                            if (message.CData.PlcmntID) {
                                store.dispatch(getFeedStateRFID(data));
                            }
                        } else if (
                            this.checkCommandByType(
                                message.Command,
                                MessageCommands.GET_FEED_STATE_DELTA
                            )
                        ) {
                            store.dispatch(getFeedStateDelta(data));
                        } else if (
                            this.checkCommandByType(
                                message.Command,
                                MessageCommands.GET_FEED_RFID_STATE_DELTA
                            )
                        ) {
                            store.dispatch(getFeedStateRFIDDelta(data));
                        } else if (
                            this.checkCommandByType(
                                message.Command,
                                GatawayCommandTypes.PING
                            )
                        ) {
                            store.dispatch(
                                onPingSuccess(message, message.DeviceId[0])
                            );
                        } else if (
                            this.checkCommandByType(
                                message.Command,
                                GatawayCommandTypes.FOUND_ETH_DEVICE_DELTA
                            )
                        ) {
                            store.dispatch(foundETHDevice(message.CAnsw));
                        } else if (
                            this.checkCommandByType(
                                message.Command,
                                GatawayCommandTypes.GET_PARAM_ETH_DEVICE
                            )
                        ) {
                            store.dispatch(gotETHDeviceParams(message.CAnsw));
                        } else if (
                            this.checkCommandByType(
                                message.Command,
                                GatawayCommandTypes.GET_FEEDING_DATA_FOR_PIG_SYNC
                            )
                        ) {
                            store.dispatch(setFeedingForPig(message.CAnsw));
                        } else if (
                            this.checkCommandByType(
                                message.Command,
                                GatawayCommandTypes.GET_UPLOAD_STATUS_DELTA
                            )
                        ) {
                            store.dispatch(
                                getUploadDeviceStatusDelta(
                                    message.CAnsw,
                                    message.DeviceId
                                )
                            );
                        } else if (
                            this.checkCommandByType(
                                message.Command,
                                GatawayCommandTypes.GET_DATA_BY_LOCATION
                            )
                        ) {
                            store.dispatch(
                                changeMapShadow(message.CAnsw, data)
                            );
                        } else if (
                            this.checkCommandByType(
                                message.Command,
                                GatawayCommandTypes.GET_DATA_BY_LOCATION_DELTA
                            )
                        ) {
                            store.dispatch(
                                changeMapShadowDelta(message.CAnsw, data)
                            );
                        } else if (this.checkCommandByType(message.Command, GatawayCommandTypes.OFFLINE_SYNC_DEVICE)) {
                            if (/electron/i.test(navigator.userAgent)) {
                                store.dispatch(offlineSyncDevice([message.CAnsw], topic.split("/")[4]));
                            }
                        } else if (this.checkCommandByType(message.Command, GatawayCommandTypes.SET_OFFLINE_FORCE_GLOBAL_SYNC)) {
                            if (/electron/i.test(navigator.userAgent)) {
                                store.dispatch(offlineSyncDevice(message.CAnsw.devices, topic.split("/")[4]));
                            }
                        }
                    }
                }
                if (this.checkIfWaitedForThisMessage(message)) {
                    let waitedMessage = this.waitedSetMessage.find(
                        (item) => item.message.MsgId === message.MsgId
                    );
                    waitedMessage.callback(null, message);
                }
            }
            if (message.PktType === MessageTypes.LAMBDA) {
                let counters = this.counters;

                if (message.Command === "ASYNC_INVOKE_STATUS") {
                    manageAsyncNotification(message);
                } else {
                    async function backgroundTask() {
                        await waitUntil(() => {
                            const state = store.getState();
                            return !isDataLoading(state);
                        });
                        const state = store.getState();
                        const {
                            location: { farm },
                            user: { user, attributes: { sub } },
                            task: { month, year },
                            language: { destination },
                        } = state;
                        if (isString(message.Table)) {
                            counters.forceUpdate[message.Table] = counters
                                .forceUpdate[message.Table]
                                ? counters.forceUpdate[message.Table] + 1
                                : 1;
                        }
                        switch (message.Table) {
                            case ForceUpdateTypes.ANIMALS:
                                if (farm) {
                                    store.dispatch(
                                        listAnimalDynamoDB(
                                            farm,
                                            false,
                                            undefined,
                                            true
                                        )
                                    );
                                }
                                break;
                            case ForceUpdateTypes.BUILDINGS:
                                if (farm) {
                                    store.dispatch(
                                        fetchBuildingsByFarmID(farm, true)
                                    );
                                }
                                break;
                            case ForceUpdateTypes.DEVICES:
                                if (farm) {
                                    store.dispatch(fetchDevices(farm, true));
                                }
                                break;
                            case ForceUpdateTypes.DICTIONARY:
                                if (user) {
                                    store.dispatch(
                                        listDictionariesDynamoDB(
                                            user.ClientID,
                                            true
                                        )
                                    );
                                }
                                break;
                            case ForceUpdateTypes.EVENTS:
                                if (farm) {
                                    store.dispatch(
                                        getEvents(farm, undefined, true)
                                    );
                                }
                                break;
                            case ForceUpdateTypes.NOTIFICATIONS:
                                //todo: fix lags on every notification insertion
                                if (user && farm) {
                                    store.dispatch(
                                        getLatestNotifications(farm)
                                    );
                                }
                                break;
                            case ForceUpdateTypes.SETTINGS:
                                if (user && farm) {
                                    store.dispatch(getSettings(user.ClientID, farm, true, { fetchResults: true }));
                                }
                                break;
                            case ForceUpdateTypes.USERS:
                                if (user) {
                                    switch (message?.CData?.accType) {
                                        case UserTypes.SERVICE: {
                                            store.dispatch(
                                                fetchServiceAccounts()
                                            );
                                            break;
                                        }
                                        case UserTypes.TRANSLATOR: {
                                            store.dispatch(fetchTranslators());
                                            break;
                                        }
                                        case UserTypes.OWNER:
                                        case UserTypes.MANAGER:
                                        default: {
                                            store.dispatch(
                                                getEmployees(
                                                    user.ClientID,
                                                    true
                                                )
                                            );
                                            break;
                                        }
                                    }
                                }
                                break;
                            case ForceUpdateTypes.GROUPS:
                                if (farm) {
                                    store.dispatch(
                                        listGroupsDynamoDB(farm, true)
                                    );
                                }
                                break;
                            case ForceUpdateTypes.ATHENA:
                                if (user && farm) {
                                    store.dispatch(
                                        listAthenaReports(
                                            farm,
                                            user.ClientID,
                                            user.LocalUserID,
                                            true
                                        )
                                    );
                                }
                                break;
                            case ForceUpdateTypes.SETTLEMENTS: {
                                if (farm) {
                                    store.dispatch(
                                        listSettlementsDynamoDB(farm, true)
                                    );
                                }
                                break;
                            }
                            case ForceUpdateTypes.TRANSLATIONS: {
                                if (
                                    user &&
                                    message.LocalUserID !== user.LocalUserID &&
                                    (user.UserType === UserTypes.SERVICE ||
                                        user.UserType ===
                                        UserTypes.TRANSLATOR ||
                                        user.Roles.find(
                                            (r) =>
                                                r.Role === RoleTypes.TRANSLATION
                                        ))
                                ) {
                                    const currentLang = JSON.parse(
                                        localStorage.getItem(
                                            `$_${user?.LocalUserID}`
                                        )
                                    )?.language;
                                    if (
                                        [currentLang, destination].includes(
                                            message?.Language
                                        )
                                    ) {
                                        store.dispatch(
                                            getTranslation(
                                                [message?.Language],
                                                false,
                                                true
                                            )
                                        );
                                    }
                                }
                                break;
                            }
                            case ForceUpdateTypes.CHANGE_CLIENT: {
                                if (farm && message.FarmID !== farm) {
                                    history.push("/logout");
                                }
                                break;
                            }
                            case ForceUpdateTypes.ISSUE_TRACKING_SYSTEM: {
                                if (
                                    checkIfUserHasRole(
                                        RoleTypes.ITS_READ,
                                        message.CData.FarmID
                                    ) ||
                                    checkIfUserHasAccessToProject(
                                        message.CData.FarmID
                                    )
                                ) {
                                    console.log("fwfwwfw");
                                    if (isMobile()) {
                                        const {
                                            active = window.location.pathname.includes(
                                                "/mobile/tasks"
                                            )
                                                ? "all"
                                                : "currentUser",
                                            index
                                        } = queryString.decode(
                                            window.location.search.replace(
                                                "?",
                                                ""
                                            )
                                        );
                                        const isProjectTab = window.location.pathname.includes(
                                            "/project/"
                                        )
                                        const getProjectID = (url) => {
                                            const match = url.match(/\/project\/([a-zA-Z0-9-]+)\//);
                                            return match ? match[1] : null; 
                                        }

                                        const ProjID = isProjectTab ? getProjectID(window.location.pathname) : null
                                        // const FarmID = isProjectTab ?  : null
                                        if (index !== undefined) {
                                            const date = moment
                                                .utc()
                                                .add(+index, "months")
                                                .startOf("month");
                                            store.dispatch(
                                                getTasksAPI(
                                                    {
                                                        DtaPlannedFrom: +date
                                                            .clone()
                                                            .subtract(
                                                                1,
                                                                "month"
                                                            )
                                                            .subtract(
                                                                1,
                                                                "week"
                                                            ),
                                                        DtaPlannedTo: +date
                                                            .clone()
                                                            .endOf("month")
                                                            .add(1, "month")
                                                            .add(2, "weeks"),
                                                        IsDone: true,
                                                        IsOpened: true,
                                                        forceFetch: true,
                                                        ProjID
                                                    },
                                                    false,
                                                    true,
                                                    false
                                                )
                                            );
                                        } else if (
                                            active === "currentUser" ||
                                            active === "allUsers"
                                        ) {
                                            store.dispatch(
                                                getTasksAPI(
                                                    {
                                                        DtaPlanned: +moment
                                                            .utc()
                                                            .startOf("day"),
                                                        AssignTo:
                                                            active ===
                                                                "currentUser"
                                                                ? user.LocalUserID
                                                                : undefined,
                                                        IsDone: true,
                                                        IsOpened: true,
                                                        forceFetch: true,
                                                        ProjID
                                                    },
                                                    false,
                                                    true,
                                                    false
                                                )
                                            );
                                        } else {
                                            for (let date of message.CData
                                                .affectedDates) {
                                                store.dispatch(
                                                    getTasksWithReplace({
                                                        DtaPlanned: date,
                                                        IsDone: true,
                                                        IsOpened: true,
                                                        forceFetch: true,
                                                        FarmID: ProjID
                                                    })
                                                );
                                            }
                                        }
                                    } else if (
                                        window.location.pathname.includes(
                                            "/task"
                                        ) ||
                                        window.location.pathname.includes(
                                            "/project"
                                        )
                                    ) {
                                        console.log("qweqweqweeqwqew");
                                        const event = new Event("getTask");
                                        event.message = message;
                                        document.dispatchEvent(event);
                                    } else {
                                        const date = moment
                                            .utc()
                                            .month(month)
                                            .year(year)
                                            .startOf("month");
                                        store.dispatch(
                                            getTasksAPI(
                                                {
                                                    DtaPlannedFrom: +date
                                                        .clone()
                                                        .subtract(1, "month")
                                                        .subtract(1, "week"),
                                                    DtaPlannedTo: +date
                                                        .clone()
                                                        .endOf("month")
                                                        .add(1, "month")
                                                        .add(2, "weeks"),
                                                    IsOpened: true,
                                                    IsDone: true,
                                                    forceFetch: true,
                                                },
                                                false,
                                                false,
                                                false
                                            )
                                        );
                                        break;
                                    }
                                }
                                break;
                            }
                            case ForceUpdateTypes.LOGOUT: {
                                if (
                                    !store.getState().maintenance.inMaintenance
                                ) {
                                    store.dispatch(show(DelayActionModal));
                                }
                                break;
                            }
                            case ForceUpdateTypes.SESSION: {
                                const retrievedToken =
                                    localStorage.getItem("sessionToken");
                                if (
                                    message?.SessionToken &&
                                    retrievedToken !== message?.SessionToken
                                ) {
                                    logout();
                                }
                                break;
                            }
                            case ForceUpdateTypes.MAINTENANCE: {
                                store.dispatch(
                                    checkMaintenanceStatus(undefined, true)
                                );
                                break;
                            }
                            case ForceUpdateTypes.FILES: {
                                if (window.location.href.includes("files?")) {
                                    store.dispatch(getLastModifiedFiles(true));
                                    const { path } = message.CData;
                                    let search = window.location.search;
                                    let params = queryString.parse(
                                        search.replace("?", "")
                                    );
                                    // user jest aktualnie na tym widoku wiec reload
                                    if (path === params.path) {
                                        store.dispatch(getFiles(path, { showLoading: false, forceFetch: true }));
                                    }
                                } else if (window.location.href.includes("runProtocol")) {
                                    store.dispatch(getCreatedProtocols({ forceFetch: true }));
                                }
                                break;
                            }
                            case ForceUpdateTypes.SALES: {
                                getSalesAPI(
                                    user.ClientID,
                                    user.LocalUserID,
                                    farm,
                                    user,
                                    null,
                                    true
                                ).then(() => {
                                    store.dispatch(getSales(farm));
                                });
                                break;
                            }
                            case ForceUpdateTypes.PREFERENCES:
                                store.dispatch(getPreferences({ forceFetch: true }));
                                break;
                            case ForceUpdateTypes.ECONOMY:
                                getEconomy(
                                    user.ClientID,
                                    user.LocalUserID,
                                    farm,
                                    null,
                                    undefined,
                                    true
                                ).then(() => {
                                    store.dispatch(getEconomyAction(farm));
                                });
                                break;
                            case ForceUpdateTypes.BREEDING_COUNTERS:
                                getBreedingCounters(
                                    user.ClientID,
                                    user.LocalUserID,
                                    farm,
                                    null,
                                    undefined
                                ).then(() => {
                                    store.dispatch(
                                        getBreedingCountersAction(farm)
                                    );
                                });
                                break;
                            case ForceUpdateTypes.GET_USER:
                                store.dispatch(
                                    getUser(sub, true, undefined, true, true)
                                );
                                break;
                            case ForceUpdateTypes.PROJECT:
                                store.dispatch(getProjects());
                                break;
                            default:
                                console.warn("Nie rozpoznano tabeli");
                        }
                    }

                    if ("requestIdleCallback" in window) {
                        console.log("JEST requestIdleCallback");
                        requestIdleCallback(backgroundTask);
                    } else {
                        setTimeout(backgroundTask, 0);
                    }
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    onAggregatedDataMessageArrived(topic, message) {
        console.warn("NEW AGGREGATED DATA MESSAGE", message);
        const proceed = enableMQTTAggregatedData(message);
        if (proceed) {
            store.dispatch({
                type: "AGGREGATED_DATA_MQTT",
                payload: message,
            });
        }

    }

    onAlarmsMessageArrived(topic, message) {
        // message.CAnsw = this.decompressData(message.CAnsw);
        console.warn("NEW ALARMS MESSAGE", message);
        let alarmsIOT = new AlarmsIOT();
        alarmsIOT.onMessage(message);
    }

    onResponsesMessageArrived(topic, message) {
        console.warn(`NEW RESPONSES MESSAGE`, message);
        if (message.PktType === MessageTypes.BIG_RESPONSE) {
            if (!message.Command) return;
            // message.CAnsw = this.decompressData(message.CAnsw);
            if (isString(message.Command)) {
                message.Command = [message.Command];
            }
            store.dispatch({
                type: "TERMINAL_ADD_MESSAGE",
                payload: { [topic]: message }
            });
            if (this.checkCommands(message.Command)) {
                if (this.checkIfWaitedForThisMessage(message)) {
                    let waitedMessage = this.waitedSetMessage.find(
                        (item) => item.message.MsgId === message.MsgId
                    );
                    console.log("waited", waitedMessage);
                    waitedMessage.callback(null, message);
                    return;
                }
            }
            if (IsMappedToState(message.Command[0])) {
                this.checkResult(message, { isStateMessage: true });
            }
            //tylko DEV_STATE & GET_DAILY_USAGE MOGA ZMIENIAC SHADOWY
            // else {
            //     let shadows = store.getState().shadows.shadows;
            //     Object.keys(message.CAnsw).map(key => {
            //         if (!message.CAnsw[key].status) {
            //             let data = new Map();
            //             let shadow = shadows.get(key);
            //             data.set(key, {...shadow, ...message.CAnsw[key]});
            //             store.dispatch(changeShadowState(data));
            //         }
            //     });
            // }
        }
    }

    onAuthMessageArrive(topic, message) {
        console.warn(`NEW AUTH MESSAGE`, message);
        if (message.PktType === MessageTypes.BIG_RESPONSE) {
            // message.CAnsw = this.decompressData(message.CAnsw);
            if (message.Command && this.checkCommands(message.Command)) {
                if (this.checkIfWaitedForThisMessage(message)) {
                    let waitedMessage = this.waitedSetMessage.find(
                        (item) => item.message.MsgId === message.MsgId
                    );
                    if (message.CAnsw.code) {
                        waitedMessage.callback(message.CAnsw, null);
                    } else {
                        waitedMessage.callback(null, message);
                    }
                }
            }
        }
    }

    onAlarmStateMessageArrive(topic, message) {
        console.warn(`NEW ALARM STATE MESSAGE`, message);
        if (message.PktType === MessageTypes.BIG_RESPONSE) {
            if (
                this.checkCommandByType(
                    message.Command,
                    GatawayCommandTypes.GET_GW_ALERT_COUNTERS_CATEGORIES
                )
            ) {
                store.dispatch(getGatewayAlertsState(message));
            }
            if (this.checkIfWaitedForThisMessage(message)) {
                let waitedMessage = this.waitedSetMessage.find(
                    (item) => item.message.MsgId === message.MsgId
                );
                waitedMessage.callback(null, message);
            }
        }
    }

    onRPIResponseMessageArrive(topic, message) {
        console.warn(`NEW RPI RESPONSE MESSAGE`, message);
        if (message.command.Command === "GET_COMMANDS") {
            store.dispatch(
                onRPIGetCommands(
                    message.command,
                    topic.split("/")[3 + IOT_IDX_OFFSET]
                )
            );
        } else {
            store.dispatch(
                onRPIResponse(
                    message.command,
                    topic.split("/")[3 + IOT_IDX_OFFSET]
                )
            );
        }
    }

    /**
     * Funkcja kolekcjujaca wiadomosci wieksze niz 128kb
     * dla wiadomosci mniejszych pozostaje bez zmian.
     * Rozkodowanie wiadomosci jest na wczesniejszym etapie
     *
     * @param data
     * @param topicType
     * @return {null|any}
     */
    collectMessage(data, topicType = "") {
        //console.warn("decompressData DATA1",data)
        if (data.ChunksID) {
            let msg = this.msgChunks.get(data.ChunksID);
            if (msg) {
                //console.warn("decompressData DATA",data)
                msg[data.ChunksOffset] = data.CAnsw;
                this.msgChunks.set(data.ChunksID, msg);
                let howManyRec = 0;
                msg.forEach((value) => {
                    if (value !== {}) {
                        howManyRec++;
                    }
                });
                if (howManyRec === data.ChunksSize) {
                    let _data = "";
                    msg.map((m) => {
                        _data += m;
                    });
                    //console.warn("decompressData parse",_data,msg)

                    return this.decompressData(_data, topicType);
                }
            } else {
                //console.warn("decompressData collect",data)
                let chunks = [].fill({}, 0, data.ChunksSize);
                chunks[data.ChunksOffset] = data.CAnsw;
                this.msgChunks.set(data.ChunksID, chunks);
                return null;
            }
        } else {
            if (topicType === TopicTypes.AGGREGATED_DATA) {
                return this.decompressData(data.AggDt, topicType);
            } else {
                return this.decompressData(data.CAnsw, topicType);
            }
        }
    }

    sendRPICommand(gateway, command, ClientID, LocalUserID) {
        let id = myID();
        let tmp = {
            MsgType: MessageTypes.REQUEST,
            Command: MessageCommands.RUN_SCRIPT,
            CData: {
                command,
            },
        };
        let topic = `${STAGE}devices/rpiCommands/${ClientID}/${gateway.DevID}/${LocalUserID}`;
        this.createAndAddMessageToQueue(topic, tmp);
        return id;
    }

    sendRPIPredefinedCommand(gateway, Command, data, ClientID, LocalUserID) {
        let id = myID();
        let tmp = {
            MsgType: MessageTypes.REQUEST,
            Command,
            CData: data,
        };
        let topic = `${STAGE}devices/rpiCommands/${ClientID}/${gateway.DevID}/${LocalUserID}`;
        this.createAndAddMessageToQueue(topic, tmp);
        return id;
    }

    onMessageArrived(topic, message) {
        try {
            console.warn("NEW MESSAGE", topic, message);
            let topicSplit = topic.split("/");
            let topicType = topicSplit[1 + IOT_IDX_OFFSET];
            // console.log(topicType);
            const msgUtf8 = message.toString("utf-8");
            // console.log("message (string): %s", msgUtf8);
            let msg = JSON.parse(msgUtf8);
            console.log(msg, cloneFast(msg));
            // console.warn("NEW MESSAGE MSG", msg);
            let msgCollected;
            if (msg.CAnsw || msg.AggDt) {
                msgCollected = this.collectMessage(msg, topicType);
                if (msg.AggDt) {
                    msg.AggDt = msgCollected;
                } else {
                    msg.CAnsw = msgCollected;
                }
            } else {
                msgCollected = true;
            }

            if (msgCollected) {
                console.log("MSG COLLECTED", msg);
                const ThingID = topicSplit[3 + IOT_IDX_OFFSET];
                // long time ago every device had a gateway so any response from the device meant that the gateway is online
                // this is not the case with the farm watch pro
                if (ThingID && !ThingID.startsWith("FARM_WATCH")) {
                    const state = store.getState();
                    let currentStatus = state.mqtt.status.get(ThingID);
                    if (!currentStatus) {
                        store.dispatch(onPingSuccess({ DeviceId: ThingID }));
                    }
                }
                switch (topicType) {
                    case TopicTypes.AGGREGATED_DATA:
                        this.onAggregatedDataMessageArrived(topic, msg);
                        break;
                    case TopicTypes.ALARMS:
                        this.onAlarmsMessageArrived(topic, msg);
                        break;
                    case TopicTypes.RESPONSES:
                        this.onResponsesMessageArrived(topic, msg);
                        break;
                    case TopicTypes.STATE:
                        this.onStateMessageArrived(topic, msg);
                        break;
                    case TopicTypes.AUTH:
                        this.onAuthMessageArrive(topic, msg);
                        break;
                    case TopicTypes.ALARM_STATE:
                        this.onAlarmStateMessageArrive(topic, msg);
                        break;
                    case TopicTypes.RPI_RESPONSES:
                        this.onRPIResponseMessageArrive(topic, msg);
                        break;
                    default:
                        console.error("Nie rozpoznano kanału", topicType);
                        break;
                }
            }
        } catch (err) {
            // makes it easier to spot an invalid message
            if (err.name === "SyntaxError") {
                catchify(() => {
                    console.warn("could not parse:");
                    console.warn(message.toString("utf-8"));
                })();
            }
            console.error("tutaj", err);
        }
    }
}

const _newIOT = new NewIOT();
export default _newIOT;
